/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
export enum DeviceType {
  Battery = 'battery',
  SolarPanel = 'solar',
  Overall = 'overall',
  PowerGrid = 'grid',
  Home = 'home',
  Settings = 'cogwheel',
  Eddi = 'eddi',
  EddiPlus = 'eddiplus',
  Zappi = 'zappi',
  ZappiGLO = 'zappiGLO',
  Harvi = 'harvi',
  Libbi = 'libbi',
  Storage = 'storage',
  GenBattery = 'genBattery',
  Unknown = 'unknown'
}

export enum DevicesType {
  eddi = 'eddi',
  eddiplus = 'eddiplus',
  zappi = 'zappi',
  zappiGLO = 'zappiGLO',
  harvi = 'harvi',
  libbi = 'libbi',
  hub = 'hub',
  gateway = 'Gateway'
}

export enum ProductCodes {
  'Eddi' = 3200,
  'Eddi2Ti' = 3202,
  'EddiPlus' = 3272,
  'Zappi1' = 3300,
  'Zappi2' = 3560,
  'Zappi2Ti' = 3562,
  'ZappiGLO' = 5100,
  'Harvi' = 3170,
  'Libbi' = 3702,
  'Hub' = 3401,
  'StubLoader' = 1001,
  'BootLoader' = 1002,
  'HubTi' = 3402
}

export enum FirmwareVersions {
  'zappi2TI' = 4.239,
  'zappi2' = 5.406,
  'zappiGLO' = 5.1,
  'default' = 5.999
}

export enum DefaultFirmwareVersions {
  'zappi' = '3562S0000',
  'zappiGLO' = '5100S0000',
  'eddi' = '3202S0000',
  'eddiplus' = '3272S0000',
  'harvi' = '3170S0000',
  'libbi' = '3702S0000'
}
