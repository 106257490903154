/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Icon from 'components/elements/icon';
import { toggleMobileDrawer } from 'containers/appStore';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

interface IMenuButtonProps {
  title?: string;
  icon: string;
  size?: number;
  className?: string;
  onClick?: () => void;
  type?: string;
  route: string;
  isDisabled?: boolean;
  path: string;
  testId?: string;
  linkTestId?: string;
}

const MenuButton: FC<IMenuButtonProps> = ({
  title,
  icon,
  className,
  onClick,
  type,
  route,
  isDisabled,
  path,
  testId = 'menu-button',
  linkTestId = 'menu-link'
}) => {
  const isActive = path === route;
  const dispatch = useDispatch();

  const handleClick = () => {
    if (isDisabled) {
      return;
    }
    dispatch(toggleMobileDrawer(false));
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <button
      className={`menubutton ${className || ''} ${type || 'col'} ${isDisabled ? 'disabled' : ''} ${isActive ? 'active' : ''}`}
      type="button"
      onClick={() => handleClick()}
      data-testid={testId}
    >
      <Link to={route} data-testid={linkTestId}>
        <Icon name={isActive ? `${icon}-active` : `${icon}-active`} />
        {title && <span className="menubutton__title">{title}</span>}
      </Link>
    </button>
  );
};

export default MenuButton;
