/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import FormSelect from 'components/elements/select';
import { FC, useState } from 'react';
import { GroupBase, OptionProps, ValueContainerProps } from 'react-select';

const FLAG_PATH = 'img/flags/';

export const CustomOption = ({
  innerRef,
  innerProps,
  children,
  data
}: OptionProps<
  { label: string; value: string },
  false,
  GroupBase<{ label: string; value: string }>
>) => {
  return (
    <div className="form-select__option" ref={innerRef} {...innerProps} data-testid="customOption">
      <div className="form-country__option--content">
        {children}
        <img
          className="form-country__option--flag"
          src={`${FLAG_PATH}${String(data.value).toLowerCase()}.svg`}
          width="22"
          height="16"
          alt="flag"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export const CustomInput = ({
  children,
  innerProps,
  hasValue,
  getValue
}: ValueContainerProps<
  { label: string; value: string },
  false,
  GroupBase<{ label: string; value: string }>
>) => {
  const data = hasValue ? getValue()[0] : { label: '', value: '' };

  return (
    <div className="form-country__value" {...innerProps} data-testid="customInput">
      {children}
      <img
        className="form-country__value--flag"
        src={`${FLAG_PATH}${data.value?.toLowerCase() || 'gb'}.svg`}
        width="22"
        height="16"
        alt="phone flag"
        loading="lazy"
      />
    </div>
  );
};

interface CountrySelectProps {
  name: string;
  label?: string;
  optionList: { label: string; value: string }[];
}

const CountrySelect: FC<CountrySelectProps> = ({ name, label, optionList }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  return (
    <div className="form-country" data-testid="countrySelect">
      {label && (
        <label className={`form-country__label__${isMenuOpened ? 'opened' : 'closed'}`}>
          {label}
        </label>
      )}
      <div className={`form-country__wrapper__${isMenuOpened ? 'opened' : 'closed'} `}>
        <FormSelect
          id={name}
          name={name}
          components={{
            Option: CustomOption,
            ValueContainer: CustomInput
          }}
          optionList={optionList}
          isSearchable={false}
          setIsMenuOpened={setIsMenuOpened}
          isOverlapping={false}
        />
      </div>
    </div>
  );
};

export default CountrySelect;
