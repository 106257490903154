/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { Field } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FC } from 'react';

interface SwitchProps {
  id: string;
  name: string;
  color: string;
  disabled?: boolean;
  testId?: string;
}

const FieldSwitch: FC<SwitchProps> = ({ id, name, color, disabled, testId = 'field-switch' }) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: FieldInputProps<never>
  ) => {
    const { onChange: formikOnChange } = field;
    formikOnChange(event);
  };

  return (
    <Field name={name}>
      {({ field }: { field: FieldInputProps<never> }) => {
        return (
          <div
            className={`form-switch ${field.value ? 'checked' : 'unchecked'} ${color}`}
            data-testid={testId}
          >
            <input
              id={id}
              type="checkbox"
              data-testid="field-switch-input"
              {...field}
              onChange={(e) => {
                handleChange(e, field);
              }}
              value={field.value ? 'checked' : 'unchecked'}
              checked={field.value}
              disabled={disabled}
            />
            <label className={field.value ? 'checked' : 'unchecked'} htmlFor={id} />
          </div>
        );
      }}
    </Field>
  );
};

export default FieldSwitch;
