/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { configureStore } from '@reduxjs/toolkit';
import React from 'react';
import { Provider } from 'react-redux';

import {
  site,
  userDetails,
  vehicles,
  location,
  sharedAccounts,
  products,
  tariffs,
  schedules,
  dualTariff,
  userGraphs,
  accessManagement,
  chargingSessions,
  csvReports
} from './slices';

const reducer = {
  site,
  userDetails,
  vehicles,
  location,
  sharedAccounts,
  tariffs,
  schedules,
  dualTariff,
  userGraphs,
  products,
  accessManagement,
  chargingSessions,
  csvReports
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

type ComponentProps = {
  children: React.ReactNode;
};

export const AppStore = ({ children }: ComponentProps) => {
  return <Provider store={store}>{children}</Provider>;
};
