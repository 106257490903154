/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import dayjs from 'utils/dayjs';

export const setLocale = (language: string) => {
  switch (language) {
    case 'de':
      dayjs.locale('de');
      break;
    case 'nl':
    case 'nl-NL':
      dayjs.locale('nl');
      break;
    case 'fr':
    case 'fr-FR':
      dayjs.locale('fr');
      break;
    default:
      dayjs.locale('en');
      break;
  }
};

export const getLanguageFromCode: { [key: string]: string } = {
  'en-GB': 'en',
  de: 'de',
  'fr-FR': 'fr',
  'nl-NL': 'nl'
};

export const getCodeFromLanguage: { [key: string]: string } = {
  en: 'en-GB',
  de: 'de',
  fr: 'fr-FR',
  nl: 'nl-NL'
};
