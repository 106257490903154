/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { COUNTRY_MAP_LIST } from 'shared/js';

type Code = string | null;

const processPhoneCodeString = (code: Code) => `+${code?.substring(code.split('+').length - 1)}`;

export const processPhone = (code: Code = '', phoneNumber = '') =>
  phoneNumber ? `${processPhoneCodeString(code) || ''} ${phoneNumber || ''}` : '';

//This function is seperate from the above phone processors, it is for formatting the addresses.
export const customCountryCode = (code: Code) => {
  if (!code) return '';

  return code === 'GB' ? COUNTRY_MAP_LIST[code] : code;
};
