/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Icon from 'components/elements/icon';
import { setNotification, setError } from 'containers/appStore';
import { useAppSelector } from 'customHooks';
import { isEmpty } from 'lodash-es';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

interface IStatusbarProps {
  isPopup: boolean;
}

const Statusbar: FC<IStatusbarProps> = ({ isPopup }) => {
  const {
    statusMessage: notifications,
    notificationScrollable,
    errorMessage: errors
  } = useAppSelector((state) => state.site);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(notifications)) {
      setTimeout(() => {
        dispatch(setNotification(''));
      }, 4000);
    }
    if (!isEmpty(errors)) {
      setTimeout(() => {
        dispatch(setError(''));
      }, 4000);
    }
    // eslint-disable-next-line
  }, [notifications, errors]);

  const renderNotification = () => (
    <div className={`statusbar  ${isPopup ? 'statusbar__popup' : ''}`} data-testid="statusbar">
      <div className="statusbar__wrap">
        <Icon name="checked-black" />
        <span className="statusbar__text">{notifications}</span>
      </div>
    </div>
  );

  const renderErrors = () => (
    <div className={`statusbar ${isPopup ? 'statusbar__popup' : ''}`} data-testid="statusbar-error">
      <div className="statusbar__wrap error">
        <Icon name="close-white" />
        <span className="statusbar__text">{errors}</span>
      </div>
    </div>
  );

  const ensureNotificationVisible = () => {
    if (notificationScrollable) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };

  switch (true) {
    case notifications?.length > 0:
      ensureNotificationVisible();

      return <>{renderNotification()}</>;
    case errors?.length > 0:
      ensureNotificationVisible();

      return <>{renderErrors()}</>;
    default:
      return <></>;
  }
};

export default Statusbar;
