/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/

//generates an array of options to be passed to the Select component
//eq: '00:00', '00:15', ..., '23:45'
export const generateTimeSelectOptions = () => {
  const arr = [];

  for (let h = 0; h < 24; h++) {
    const hour = h > 9 ? h : `0${h}`;

    for (let m = 0; m < 60; m += 15) {
      const minutes = m > 9 ? m : `0${m}`;
      const str = `${hour}:${minutes}`;

      arr.push({ label: str, value: str });
    }
  }

  return arr;
};

// TODO type of value returned by this function does not correspond with Select options type
// Same issue is for function arguments, because of Formik, they don't match
export const mapTranslationToOptionList = (
  objValues: { [key: string]: any },
  objLabels: { [key: string]: any } | undefined
) =>
  Object.keys(objValues)
    .map((key) => {
      if ((objValues?.[key] || objValues?.[key] === 0) && objLabels?.[key]) {
        return { value: objValues?.[key], label: objLabels?.[key] };
      }

      return { value: '', label: '' };
    })
    .filter((it) => it);

export const generateOptionList = (arr: string[]) =>
  arr.map((elem) => ({ value: elem, label: elem }));
