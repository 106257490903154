/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { loadText } from 'api/requests';
import WelcomePopup from 'components/dashboard/welcomePopup';
import { filterAndSortDevices } from 'components/graph/chart/graphData';
import InfoMessage from 'components/infoMessage';
import Popup, { handlePopup } from 'components/popup';
import AcceptInvite from 'components/sharing/acceptInvite';
import { InvitationStatus } from 'constants/enums';
import { route } from 'containers/appRouter/route';
import {
  setGeneralPageInfoPopUp,
  setIsFirstTimeReg,
  updateDatesTimeZone
} from 'containers/appStore';
import { useAppDispatch, useAppSelector, usePopups } from 'customHooks';
import { isEmpty } from 'lodash-es';
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { INFO_MESSAGE, ACCEPT_INVITE } from 'shared/js';
import dateCreator from 'utils/dateCreator';

interface IData {
  popupTitle: string;
  popupMessage: string;
  acceptInviteTitle: string;
}

export const MyeRoot = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<IData>({
    popupMessage: '',
    popupTitle: '',
    acceptInviteTitle: ''
  });
  const [popupAlert, setPopupAlert] = useState<{ [key: string]: boolean }>({
    [INFO_MESSAGE]: false,
    [ACCEPT_INVITE]: false
  });
  const store = useAppSelector((store) => store);
  const [invitationsAvailable, setInvitationsAvailable] = useState<boolean>(false);

  // Store
  const { site, userDetails, location, products } = store;
  const { generalPageInfoPopUp } = site;
  const devicesList = products?.activeHub?.devices || [];
  const { isFirstTimeReg, personalInfo } = userDetails;
  const { locations } = location;
  const { language } = personalInfo.preferences;

  // sort devices so we get the timezone of the first device as per mobile app
  const sortedDevices = filterAndSortDevices(devicesList);
  const timeZoneRegion = sortedDevices.length
    ? sortedDevices[0].timeZoneRegion
    : dateCreator.Timezone;

  // Conditions
  const isEmptyGeneralPageInfoPopUp =
    isEmpty(generalPageInfoPopUp?.popupTitle) && isEmpty(generalPageInfoPopUp?.popupMessage);

  // Handles
  const handleInfoPopup = () => {
    if (isFirstTimeReg) {
      dispatch(setIsFirstTimeReg(false));
      setPopupAlert({ ...popupAlert, [INFO_MESSAGE]: false });
      return;
    }

    dispatch(setGeneralPageInfoPopUp({ popupTitle: '', popupMessage: '' }));
    history.push(route.DASHBOARD);
    return;
  };

  const popUps = usePopups();

  useEffect(() => {
    localStorage.setItem('storeTimeZoneRegion', timeZoneRegion);

    // set timezone for dates used in the charts from the app
    dateCreator.Timezone = timeZoneRegion;

    dispatch(updateDatesTimeZone({ timeZone: timeZoneRegion }));

    // eslint-disable-next-line
  }, [timeZoneRegion]);

  useEffect(() => {
    if (isEmpty(data)) {
      return;
    }

    if (popUps.popUpCondition) {
      dispatch(
        setGeneralPageInfoPopUp({
          popupTitle: data?.popupTitle,
          popupMessage: data?.popupMessage
        })
      );
      history.push(route.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [popUps, data]);

  useEffect(() => {
    setInvitationsAvailable(
      !isEmpty(
        locations.filter((loc) => loc.invitationData?.invitationStatus === InvitationStatus.PENDING)
      )
    );
  }, [locations, isFirstTimeReg]);

  useEffect(() => {
    if (invitationsAvailable) {
      handlePopup(popupAlert, setPopupAlert, ACCEPT_INVITE);
      return;
    }

    //eslint-disable-next-line
  }, [invitationsAvailable]);

  useEffect(() => {
    if (!isEmptyGeneralPageInfoPopUp) {
      handlePopup(popupAlert, setPopupAlert, INFO_MESSAGE);
    }

    if (isFirstTimeReg) {
      handlePopup(popupAlert, setPopupAlert, INFO_MESSAGE);
    }

    //eslint-disable-next-line
  }, [generalPageInfoPopUp, isFirstTimeReg]);

  useEffect(() => {
    if (popUps) {
      loadText(popUps.jsonToLoad, language).then((res) => setData(res?.data));
    }
  }, [popUps, language]);

  return (
    <>
      {children}
      <Popup
        className={isFirstTimeReg ? 'neonglow__green' : ''}
        render={isFirstTimeReg ? WelcomePopup : InfoMessage}
        title={generalPageInfoPopUp?.popupTitle}
        description={generalPageInfoPopUp?.popupMessage}
        condition={popupAlert}
        setter={setPopupAlert}
        name={INFO_MESSAGE}
        img={isFirstTimeReg ? '/img/welcome.png' : undefined}
        onClose={handleInfoPopup}
      />

      {invitationsAvailable && (
        <Popup
          render={AcceptInvite}
          title={data.acceptInviteTitle}
          description={generalPageInfoPopUp?.popupMessage}
          condition={popupAlert}
          setter={setPopupAlert}
          name={ACCEPT_INVITE}
          passProps={{
            locationList: location.locations
          }}
        />
      )}
    </>
  );
};
