/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { loadText } from 'api/requests';
import Icon from 'components/elements/icon';
import AddEditLocation from 'components/forms/addEditLocation';
import LocationSwitchModal from 'components/locationSwitch/locationSwitchModal/locationSwitchModal';
import Popup, { handlePopup } from 'components/popup';
import { InvitationStatus } from 'constants/enums';
import { route } from 'containers/appRouter';
import { useAppSelector } from 'customHooks';
import { isEmpty } from 'lodash-es';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { shortenString, LOCATION_SWITCH, LOCATION_SETTINGS, tracking } from 'shared/js';

import { IData, locationSwitchDataInitialValues } from './locationSwitch.types';

const LocationSwitch = () => {
  const history = useHistory();
  const [data, setData] = useState<IData>(locationSwitchDataInitialValues);
  const store = useAppSelector((store) => store);
  const [popupAlert, setPopupAlert] = useState<{ [key: string]: boolean }>({
    [LOCATION_SWITCH]: false,
    [LOCATION_SETTINGS]: false
  });

  // Store
  const { userDetails, location } = store;
  const { language } = userDetails?.personalInfo?.preferences;
  const { activeLocation, locations } = location;

  // Conditions
  const myLocation = locations.find((el) => el.address?.id === activeLocation?.address?.id);
  const totalNotifications = locations.reduce((acc, obj) => {
    return acc + (obj?.invitationData?.invitationStatus === InvitationStatus.PENDING ? 1 : 0);
  }, 0);
  const locationName =
    activeLocation?.address?.siteName || activeLocation?.address?.addressLine1 || '';
  const handleLocationRedirect = () => history.push(route.LOCATION + '#location');

  useEffect(() => {
    loadText('locationswitch', language).then((res) => setData(res?.data));
  }, [language]);

  const onAddLocationClick = () => {
    setPopupAlert({
      [LOCATION_SWITCH]: false,
      [LOCATION_SETTINGS]: true
    });
  };

  const handleonClick = () => {
    handleLocationRedirect();
    tracking({
      name: 'No location button',
      location: '/',
      action: 'click'
    });
  };

  if (isEmpty(activeLocation?.address?.id))
    return (
      <>
        <button className="locationswitch" onClick={handleonClick} data-testid={'locationswitch'}>
          <Icon name={activeLocation?.isGuestLocation ? 'house-shared-white' : 'house-white'} />
          <div className="locationswitch__name">
            <span className="locationswitch__name--missing">{data.locationMissingTitle}</span>
          </div>
          <Icon name="arrow-down-grey" size={16} />
        </button>
      </>
    );

  return (
    <>
      <button
        className="locationswitch"
        onClick={() => handlePopup(popupAlert, setPopupAlert, LOCATION_SWITCH)}
        data-testid={'locationswitch'}
      >
        <Icon name={activeLocation?.isGuestLocation ? 'house-shared-white' : 'house-white'} />
        <div className="locationswitch__name">
          <span className="locationswitch__name--hub">{shortenString(locationName, 14)}</span>
          {activeLocation?.isGuestLocation && (
            <span className="locationswitch__name--shared">
              {shortenString(
                `${data.locationSwitchPopUpSharedByLabel} ${myLocation?.invitationData?.ownerEmail}`,
                22
              )}
            </span>
          )}
        </div>
        {totalNotifications !== 0 && (
          <div className="locationswitch__notifications">{totalNotifications}</div>
        )}
        <Icon name="arrow-down-grey" size={16} />
      </button>
      <Popup
        title={data.locationSwitchPopUpTitle}
        description={data.locationSwitchPopUpDescription}
        render={LocationSwitchModal}
        condition={popupAlert}
        setter={setPopupAlert}
        name={LOCATION_SWITCH}
        className="green"
        passProps={{
          onAddClick: onAddLocationClick,
          addLabel: data.locationSwitchPopUpAddLabel,
          acceptLabel: data.locationSwitchPopUpAcceptLabel,
          ignoreLabel: data.locationSwitchPopUpIgnoreLabel,
          sharedByLabel: data.locationSwitchPopUpSharedByLabel,
          error: data.error
        }}
      />
      <Popup
        title={data.locationPopUpTitle}
        description={data.locationPopUpDescription}
        render={AddEditLocation}
        condition={popupAlert}
        setter={setPopupAlert}
        name={LOCATION_SETTINGS}
        className="green"
      />
    </>
  );
};

export default LocationSwitch;
