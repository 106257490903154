/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { FC } from 'react';

interface IconProps {
  name?: string;
  size?: number;
  testId?: string;
  className?: string;
  onClick?: () => void;
}

const Icon: FC<IconProps> = ({ name, className = '', onClick, testId = 'icon', size }) => {
  const svg = `${process.env.PUBLIC_URL}/svg/${name}.svg`;

  return (
    <div
      data-testid={testId}
      className={`icon icon-${name?.toLowerCase()} ${className}`}
      onClick={() => onClick && onClick()}
    >
      <object
        data={svg}
        type="image/svg+xml"
        className="icon-object"
        aria-label={`icon-${name}`}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <img src={svg} alt={`icon-${name}`} loading="lazy" />
      </object>
    </div>
  );
};

export default Icon;
