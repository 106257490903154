/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = Object.freeze({
  aws_project_region: `${process.env.REACT_APP_REGION}`,
  aws_appsync_graphqlEndpoint: `${process.env.REACT_APP_GRAPHQLENDPOINT}`,
  aws_appsync_region: `${process.env.REACT_APP_REGION}`,
  aws_appsync_authenticationType: `${process.env.REACT_APP_AUTHENTICATIONTYPE}`,
  aws_appsync_apiKey: `${process.env.REACT_APP_APIKEY}`,
  aws_cognito_region: `${process.env.REACT_APP_REGION}`,
  aws_user_pools_id: `${process.env.REACT_APP_POOLSID}`,
  aws_user_pools_web_client_id: `${process.env.REACT_APP_POOLSWEBID}`,
  oauth: {
    domain: `${process.env.REACT_APP_OAUTH_DOMAIN}`,
    scope: `${process.env.REACT_APP_OAUTH_SCOPE}`.split(' '),
    redirectSignIn: `${process.env.REACT_APP_OAUTH_REDIRECTSIGNIN}`,
    redirectSignOut: `${process.env.REACT_APP_OAUTH_REDIRECTSIGNOUT}`,
    responseType: `${process.env.REACT_APP_OAUTH_RESPONSETYPE}`
  }
});

export default awsconfig;
