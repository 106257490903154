/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { route, getConnectedRoute } from 'containers/appRouter';
import { setAuthState } from 'containers/appStore';
import { useAppSelector } from 'customHooks';
import { isEmpty } from 'lodash-es';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import {
  NORMAL_REGISTRATION,
  SIGNED_IN,
  SIGNED_OUT,
  SOCIAL_SIGNED_IN,
  VERIFICATION
} from 'shared/js';

import { getToken } from './authHelpers';

type ComponentProps = {
  children: React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthController: FC<ComponentProps> = ({ children }): any => {
  const dispatch = useDispatch();
  const { authState } = useAppSelector((store) => store.userDetails);
  const location = useLocation().pathname;
  const isContent = getConnectedRoute(location);

  useEffect(() => {
    getToken().then((res) => {
      if (isContent && res === 'The user is not authenticated') {
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem('token', SIGNED_OUT);
        }
        dispatch(setAuthState(SIGNED_OUT));
        window.location.href = window.location.origin + '/login';
        return;
      }
    });

    if (authState === null && !isEmpty(localStorage.getItem('token'))) {
      dispatch(setAuthState(localStorage.getItem('token') || ''));
      return;
    }

    if (localStorage.getItem('token') === SOCIAL_SIGNED_IN) {
      dispatch(setAuthState(SOCIAL_SIGNED_IN));
      return;
    }

    if (localStorage.getItem('token') === SIGNED_IN) {
      dispatch(setAuthState(SIGNED_IN));
      return;
    }

    if (
      localStorage.getItem('token') === SIGNED_OUT &&
      location !== route.LOGIN &&
      location !== route.FORGOT_PASSWORD
    ) {
      dispatch(setAuthState(SIGNED_OUT));
      window.location.href = window.location.origin + '/login';
      return;
    }

    // when landing on /login page while coming from /registration page, it would redirect you to /registration
    // we need this to ensure we don't get redirected to /registration page on back button click
    const isLoginWithRegToken =
      location === route.LOGIN && localStorage.getItem('token') === NORMAL_REGISTRATION;

    // redirect to login page
    if (isEmpty(localStorage.getItem('token')) || isLoginWithRegToken) {
      localStorage.setItem('token', SIGNED_OUT);
      dispatch(setAuthState(SIGNED_OUT));
      window.location.href = window.location.origin + '/login';
      return;
    }
  });

  switch (true) {
    case authState === null:
      return children;
    case authState === NORMAL_REGISTRATION && location !== route.REGISTRATION:
      return <Redirect to={route.REGISTRATION} />;
    case authState === NORMAL_REGISTRATION &&
      (location === route.REGISTRATION || location === route.VERIFICATION):
      return children;
    case authState === SIGNED_IN && !isContent:
      return <Redirect to={route.DASHBOARD} />;
    case authState === SIGNED_OUT && isContent:
      return <Redirect to={route.LOGIN} />;
    case authState === VERIFICATION && location === route.LOGOUT:
      return children;
    case authState === VERIFICATION && isContent:
      return <Redirect to={route.VERIFICATION} />;
    case authState === SIGNED_IN && location === route.SOCIAL_REGISTRATION:
      return <Redirect to={route.DASHBOARD} />;
    case authState === SIGNED_OUT && location === route.SOCIAL_REGISTRATION:
      return <Redirect to={route.LOGIN} />;
    case authState === SOCIAL_SIGNED_IN && location !== route.SOCIAL_REGISTRATION:
      return <Redirect to={route.SOCIAL_REGISTRATION} />;
    default:
      return children;
  }
};
