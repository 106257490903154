/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { loadText } from 'api/requests';
import Button from 'components/elements/button';
import { BUTTON_COLORS } from 'components/elements/button/consts';
import { useAppSelector } from 'customHooks';
import { useState, useEffect, FC } from 'react';

interface IWelcomePopup {
  onClose?: () => void;
  testId?: string;
}

interface IWelcomePopupData {
  popup?: {
    title?: string;
    desc?: string;
    button?: string;
  };
}

const WelcomePopup: FC<IWelcomePopup> = ({ onClose, testId = 'welcome-popup' }) => {
  const [data, setData] = useState<IWelcomePopupData>({});
  const { language } = useAppSelector((store) => store.userDetails.personalInfo.preferences);
  const { popup } = data;

  const handleClick = () => {
    onClose && onClose();
  };

  useEffect(() => {
    loadText('dashboard', language).then((res) => setData(res?.data));
  }, [language]);

  return (
    <div className="welcome-popup" data-testid={testId}>
      <span className="welcome-popup__title">{popup?.title}</span>
      <span className="welcome-popup__desc">{popup?.desc}</span>
      <Button
        className={`welcome-popup__button ${BUTTON_COLORS.green} ${BUTTON_COLORS.filled}`}
        onClick={handleClick}
        label={popup?.button}
      />
    </div>
  );
};

export default WelcomePopup;
