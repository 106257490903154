/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { isEmpty } from 'lodash-es';

//creates an array with numbers from start to end with a given step
export const range = (start: number, stop: number, step: number) => {
  if (!step) step = 1;
  return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
};

//splits an array of numbers in parts formed by consecutive numbers
export const splitArrayByIncreasingSequences = (arr: number[]) => {
  if (isEmpty(arr)) {
    return [];
  }
  const res = [[arr[0]]];
  let cIdx = 0;

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] === arr[i - 1] + 1) {
      res[cIdx] = [...res[cIdx], arr[i]];
    } else {
      cIdx++;
      res[cIdx] = [arr[i]];
    }
  }

  return res;
};
