/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import {
  acceptAccountSharingInvitation,
  rejectAccountSharingInvitation,
  getUserEnergySetup,
  getUserHubsAndDevices
} from 'api/methods';
import Button from 'components/elements/button';
import { BUTTON_COLORS } from 'components/elements/button/consts';
import Icon from 'components/elements/icon';
import IconButton from 'components/elements/iconButton';
import { InvitationStatus } from 'constants/enums';
import { route } from 'containers/appRouter';
import {
  setError,
  setLoading,
  setActiveLocation,
  setLocations,
  setActiveLocationId,
  setDevicesAndHubList,
  processDeviceList,
  setActiveDevice
} from 'containers/appStore';
import { useAppDispatch, useAppSelector } from 'customHooks';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from 'types';

import { ILocationSwitchModal } from '../locationSwitch.types';

const LocationSwitchModal: FC<ILocationSwitchModal> = ({
  onClose,
  onAddClick,
  sharedByLabel,
  addLabel,
  acceptLabel,
  ignoreLabel,
  error,
  testId = 'locationswitchmodal'
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { location } = useAppSelector((store) => store);

  // Store;
  const { locations, activeLocation } = location;

  const handleCancel = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    return;
  };

  const switchLocation = (location: Location) => {
    if (location?.invitationData?.invitationStatus === InvitationStatus.PENDING) {
      handleCancel();
      return;
    }

    dispatch(setActiveLocation(location));
    dispatch(setActiveLocationId(location?.energySetup?.id));
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('activeLocation', location?.energySetup?.id);
    }
    dispatch(setActiveDevice(''));

    handleCancel();
    return;
  };

  const handleSettingsRedirect = (location: Location | null) => {
    if (location) {
      switchLocation(location);
    }
    history.push(route.LOCATION + '#location');
    handleCancel();
  };

  const handleCancelInvitation = (invitationId: string | undefined) => {
    rejectAccountSharingInvitation({ invitationId }).then((res) => {
      if (!res?.status || res?.statusCode > 300) {
        dispatch(setError(res?.message || error));
        dispatch(setLoading(false));
        return;
      }

      getUserEnergySetup().then((res) => {
        if (!res?.status || res?.statusCode >= 300) {
          dispatch(setError(res?.message || error));
          return;
        }
        dispatch(setLocations(res?.content));
      });
    });

    history.push(route.DASHBOARD);
  };

  const handleAcceptInvitation = (invitationId: string | undefined) => {
    dispatch(setLoading(true));

    acceptAccountSharingInvitation({ invitationId }).then((res) => {
      if (!res?.status || res?.statusCode > 300) {
        dispatch(setError(res?.message || error));
        dispatch(setLoading(false));
        return;
      }

      getUserEnergySetup().then((res) => {
        if (!res?.status || res?.statusCode > 300) {
          dispatch(setError(res?.message || error));
          return;
        }
        dispatch(setLocations(res?.content));
      });

      getUserHubsAndDevices({ invitationId }).then((res) => {
        if (!res?.status || res?.statusCode > 300) {
          dispatch(setError(res?.message || error));
          return;
        }

        dispatch(setDevicesAndHubList(processDeviceList(res?.content)));
      });

      dispatch(setLoading(false));
      history.push(route.DASHBOARD);
    });
  };

  interface IRenderLocation {
    location: Location;
  }

  const RenderLocation = ({ location }: IRenderLocation) => {
    const invitationStatusIsPending =
      location?.invitationData?.invitationStatus === InvitationStatus.PENDING;
    const isActiveLocation = location?.address?.id === activeLocation?.address?.id;

    return (
      <>
        <div
          className={`location-switch-item ${isActiveLocation ? 'active' : ''} ${location.invitationData ? 'invitation' : ''}`}
          data-testid={testId}
        >
          <div
            className={`location-switch-item__wrapper ${location.invitationData ? 'invitation' : ''}`}
            onClick={() => switchLocation(location)}
          >
            <Icon
              name={`house-${location?.isGuestLocation ? 'shared-' : ''}${isActiveLocation ? 'black' : 'white'}`}
            />
            <div className="location-switch-item__content">
              <h4>{location?.address?.siteName || location?.address?.addressLine1}</h4>
              <p>{location?.address?.addressLine1}</p>
              {location?.isGuestLocation && (
                <span>{`${sharedByLabel} ${location?.invitationData?.ownerFirstName || location?.invitationData?.ownerEmail}`}</span>
              )}
              {invitationStatusIsPending && (
                <div className="location-switch-item__invitation">
                  <Button
                    className={BUTTON_COLORS.green}
                    label={ignoreLabel}
                    onClick={() => handleCancelInvitation(location?.invitationData?.invitationId)}
                    testId={`${testId}-cancelInvitation`}
                  />
                  <Button
                    className={`${BUTTON_COLORS.green} ${BUTTON_COLORS.filled}`}
                    label={acceptLabel}
                    onClick={() => handleAcceptInvitation(location?.invitationData?.invitationId)}
                    testId={`${testId}-acceptInvitation`}
                  />
                </div>
              )}
            </div>
          </div>
          {!(
            location.invitationData &&
            location.invitationData?.invitationStatus === InvitationStatus.PENDING
          ) && (
            <Icon
              name="cogwheel"
              onClick={() => handleSettingsRedirect(!isActiveLocation ? location : null)}
              testId={`${testId}-cogwheel`}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {locations.map((location, i) => (
        <RenderLocation location={location} key={i} />
      ))}
      <IconButton
        color="green"
        className="flat"
        label={addLabel}
        onClick={onAddClick}
        icon="plusGreen"
      />
    </>
  );
};

export default LocationSwitchModal;
