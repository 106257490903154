/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Textbox from 'components/elements/textbox';
import { useAppSelector } from 'customHooks';
import { Dayjs } from 'dayjs';
import { useFormikContext } from 'formik';
import { FC, useState } from 'react';
import ReactModal from 'react-modal';
import { dateFormated, DATE_FORMATS } from 'shared/js';
import dayjs from 'utils/dayjs';

import Calendar from './calendar';
import { DatePickerProps } from './datepicker.types';

const DatePicker: FC<DatePickerProps> = (props) => {
  const {
    id,
    name,
    label,
    startingRestrictedDate,
    endingRestrictedDate,
    calendarYearStart,
    calendarYearEnd,
    validation,
    placeholder
  } = props;
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const { userDetails } = useAppSelector((state) => state);

  const { personalInfo } = userDetails;
  const { dateFormat } = personalInfo.preferences;

  const { setValues, values } = useFormikContext<{ [key: string]: Dayjs }>();

  const renderDate = (date: Dayjs) =>
    /* istanbul ignore next */
    // eslint-disable-next-line no-nested-ternary
    date
      ? dateFormated(dateFormat, null) === DATE_FORMATS.DD_MM_YYYY
        ? dayjs(date).format('DD MMMM, YYYY')
        : dayjs(date).format('LL')
      : '';

  return (
    <div className="form-datepicker" data-testid="form-datepicker">
      <div className="form-datepicker__input">
        <div
          data-testid="datepickerW"
          onClick={() => {
            setIsDatepickerOpen(!isDatepickerOpen);
          }}
        >
          <Textbox
            id={id}
            name={name}
            type="text"
            label={label}
            value={renderDate(values?.[name])}
            isControlled
            iconName="calendar-white"
            placeholder={placeholder}
          />
        </div>
      </div>

      <ReactModal
        isOpen={isDatepickerOpen}
        className="form-datepicker__calendar"
        ariaHideApp={false}
      >
        <Calendar
          initialDate={values?.[name]}
          onChange={(mo) => setValues({ ...values, [name]: mo })}
          onClose={() => setIsDatepickerOpen(!isDatepickerOpen)}
          validation={validation}
          startingRestrictedDate={startingRestrictedDate}
          endingRestrictedDate={endingRestrictedDate}
          calendarYearStart={calendarYearStart}
          calendarYearEnd={calendarYearEnd}
        />
      </ReactModal>
    </div>
  );
};

export default DatePicker;
