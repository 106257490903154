/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { isEmpty } from 'lodash-es';
import { forwardRef } from 'react';

import arrow from './handle-oval.svg';
import { buildChartOptions } from './helpers';

const Chart = forwardRef((props, ref) => {
  const {
    // Chart configs
    dataSets,
    visibleMinX,

    // Other configs reaching charts as props
    // visibleRange,
    // unitMeasurement,
    // minY,
    //animation,

    // Local states
    isError,
    isComplete,
    lastUpdate,

    // Localization
    textData
  } = props || {};

  const renderCharts = () => {
    const checkIfDataSetsIsEmpty = dataSets
      ?.map((dataSet) => isEmpty(dataSet.data))
      ?.every((emptyDataSet) => emptyDataSet === true);
    switch (true) {
      case isEmpty(dataSets) || checkIfDataSetsIsEmpty:
        return <span className="graph-chart__error">{textData?.noData}</span>;
      case isError:
        return <span className="graph-chart__error">{textData?.isError}</span>;
      case !isComplete:
        return <span className="graph-chart__error">{textData?.isComplete}</span>;
      default:
        return (
          <HighchartsReact
            key={`${visibleMinX}_${lastUpdate}`}
            highcharts={Highcharts}
            constructorType={'stockChart'}
            options={buildChartOptions({ ...props, arrows: arrow })}
          />
        );
    }
  };

  return (
    <div ref={ref} className="graph-chart">
      {renderCharts()}
    </div>
  );
});

export default Chart;
