/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Icon from 'components/elements/icon';
import Statusbar from 'components/elements/statusbar';
import { setError, setFormErrorMessage, setNotification } from 'containers/appStore';
import React, { ElementType, FC, useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { tracking } from 'shared/js';

interface IPopup {
  title?: string;
  description?: string | React.ReactNode;
  render: ElementType;
  condition: { [key: string]: boolean };
  setter: React.Dispatch<{ [key: string]: boolean }>;
  name: string;
  className?: string;
  passProps?: {
    // eslint-disable-next-line
    [x: string]: any;
  }; // @proptype Object
  color?: string;
  onClose?: () => void;
  overwriteClose?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  img?: string;
}

export const handlePopup = (
  condition: { [key: string]: boolean },
  setter: React.Dispatch<{ [key: string]: boolean }>,
  name: string
) => {
  setter({
    ...condition,
    [name]: !condition[name]
  });
};

const Popup: FC<IPopup> = ({
  title,
  description,
  render,
  condition,
  setter,
  name,
  className = '',
  passProps, // @proptype Object
  color = 'green',
  onClose,
  overwriteClose = false,
  shouldCloseOnOverlayClick = true,
  img
}) => {
  const isModalOpen = condition[name];
  const Component = render;
  const dispatch = useDispatch();
  const popupRef = useRef<null | HTMLDivElement>(null);
  const location = useLocation();

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }

    if (!overwriteClose) {
      handlePopup(condition, setter, name);
    }

    tracking({
      name: `${name} close popup`,
      location: `${location.pathname.replace('/', '')}/ ${location.hash.replace('#', '')}`,
      action: 'click'
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      dispatch(setNotification(''));
      dispatch(setError(''));
      dispatch(setFormErrorMessage(''));
      popupRef?.current?.scrollIntoView();
    }
    // eslint-disable-next-line
  }, [isModalOpen]);

  if (img) {
    return (
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleClose}
        className={`popup__image ${className} ${color}`}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        {img && (
          <img src={img} className="popup__image--image" alt={`img-${name}`} loading="lazy" />
        )}
        <div className="popup__image--content">
          <Component
            condition={condition}
            setter={setter}
            name={name}
            color={color}
            onClose={handleClose}
            {...passProps}
          />
        </div>
      </ReactModal>
    );
  }

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      className={`neonglow__${color} popup ${className}`}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <Statusbar isPopup={true} />
      <div className="popup__close" ref={popupRef}>
        <button className="popup__button" type="button" onClick={handleClose}>
          <Icon name={'close-grey'} />
        </button>
      </div>
      {title && <h3 className={`popup__title popup__title-${color}`}>{title}</h3>}
      {description && <span className="popup__description">{description}</span>}
      <div className="popup__content">
        <Component
          condition={condition}
          setter={setter}
          name={name}
          color={color}
          onClose={handleClose}
          {...passProps}
          {...popupRef}
        />
      </div>
    </ReactModal>
  );
};

export default Popup;
