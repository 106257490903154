/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import DataLayer from 'components/dataLayer/dataLayer';
import Loading from 'components/loading';
import Layout from 'components/platform/layout';
import Menu from 'components/platform/menu';

/* Cognito control and checks */
import { AuthController } from 'containers/authController';

/* Outstanding root level logic that it's shared across the platform */
import { MyeRoot } from 'containers/myeRoot';
import { Suspense } from 'react';
import { lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { route } from './route';

// PAGES
const Login = lazy(() => import('pages/login'));
const Logout = lazy(() => import('pages/logout'));
const SignUp = lazy(() => import('pages/registration'));
const SocialSignUp = lazy(() => import('pages/socialRegistration'));
const VerificationPage = lazy(() => import('pages/verification'));
const Dashboard = lazy(() => import('pages/dashboard'));
const MyAccount = lazy(() => import('pages/myAccount'));
const MyLocation = lazy(() => import('pages/myLocation'));
const DualTariff = lazy(() => import('pages/dualTariff'));
const AgileTariff = lazy(() => import('pages/agileTariff'));
const EnergyUsage = lazy(() => import('pages/energyUsage'));
const Error404 = lazy(() => import('pages/error404'));
const ExtendedWarranty = lazy(() => import('pages/extendedWarranty'));
const ExtendedWarrantyCancel = lazy(() => import('pages/extendedWarrantyCancel'));
const ResetPassword = lazy(() => import('pages/resetPassword'));
const ExtendedWarrantySuccess = lazy(() => import('pages/extendedWarrantySuccess'));
const Schedules = lazy(() => import('pages/schedules'));
const DataReports = lazy(() => import('pages/dataReports'));

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Layout>
        <MyeRoot>
          <AuthController>
            <DataLayer>
              <Menu />
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route exact path={route.DASHBOARD} component={Dashboard} />
                  <Route path={route.LOGIN} component={Login} />
                  <Route path={route.LOGOUT} component={Logout} />
                  <Route path={route.REGISTRATION} component={SignUp} />
                  <Route path={route.SOCIAL_REGISTRATION} component={SocialSignUp} />
                  <Route path={route.VERIFICATION} component={VerificationPage} />
                  <Route path={route.FORGOT_PASSWORD} component={ResetPassword} />
                  <Route path={route.ACCOUNT} component={MyAccount} />
                  <Route path={route.LOCATION} component={MyLocation} />
                  <Route path={route.DATA_REPORTS} component={DataReports} />
                  <Route path={route.AGILE_TARIFF} component={AgileTariff} />
                  <Route path={route.DUAL_TARIFF} component={DualTariff} />
                  <Route path={route.SCHEDULES} component={Schedules} />
                  <Route path={route.ENERGY_USAGE} component={EnergyUsage} />
                  <Route path={route.EXTENDED_WARRANTY} component={ExtendedWarranty} />
                  <Route path={route.EXTENDED_WARRANTY_CANCEL} component={ExtendedWarrantyCancel} />
                  <Route path={route.EW_SUCCESS} component={ExtendedWarrantySuccess} />
                  <Route component={Error404} />
                </Switch>
              </Suspense>
            </DataLayer>
          </AuthController>
        </MyeRoot>
      </Layout>
    </BrowserRouter>
  );
};
