/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import {
  acceptAccountSharingInvitation,
  rejectAccountSharingInvitation,
  getUserEnergySetup,
  getUserHubsAndDevices
} from 'api/methods';
import { loadText } from 'api/requests';
import Button from 'components/elements/button';
import { BUTTON_TYPE } from 'components/elements/button/consts';
import { BUTTON_COLORS } from 'components/elements/button/consts';
import { InvitationStatus } from 'constants/enums';
import { route } from 'containers/appRouter';
import {
  setError,
  setLoading,
  setLocations,
  setActiveLocation,
  setActiveHub,
  setDevicesAndHubList,
  processDeviceList
} from 'containers/appStore';
import { useAppSelector } from 'customHooks';
import { FC } from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  AcceptInviteProps,
  IAcceptInviteData,
  acceptInviteDataInitialValues
} from './acceptInvite.types';

const AcceptInvite: FC<AcceptInviteProps> = ({ locationList, onClose }) => {
  const dispatch = useDispatch();

  const store = useAppSelector((state) => state);
  const [data, setData] = useState<IAcceptInviteData>(acceptInviteDataInitialValues);
  const [noOfInvites, setNoOfInvites] = useState<number>(
    locationList.filter((x) => x.invitationData !== null).length
  );

  const history = useHistory();

  const hubId = store?.products?.hubList?.[0]?.id;
  const { activeHub } = store?.products;
  const activeLocationId = store?.location?.activeLocation?.address?.id;
  const {
    preferences: { language }
  } = store.userDetails.personalInfo;

  useEffect(() => {
    loadText('acceptinvite', language).then((res) => setData(res?.data));
  }, [language]);

  const handleSubmit = (invitationID: string | undefined) => {
    dispatch(setLoading(true));

    acceptAccountSharingInvitation({ invitationId: invitationID }).then((res) => {
      if (!res.status || res.statusCode > 300) {
        dispatch(setError(res.message || data.error));
        return;
      }
      getUserEnergySetup().then((res) => {
        if (!res.status || res.statusCode >= 300) {
          console.error(res);
          return;
        }

        dispatch(setLocations(res?.content));

        if (!activeHub && !activeLocationId) {
          dispatch(setActiveLocation(res.content?.[0]));
          dispatch(setActiveHub(hubId));
        }
        return;
      });

      getUserHubsAndDevices({ invitationID }).then((res) => {
        if (!res.status || res.statusCode >= 300) {
          console.error(res);
          return;
        }

        dispatch(setDevicesAndHubList(processDeviceList(res?.content)));
      });
    });

    dispatch(setLoading(false));
    setNoOfInvites(noOfInvites - 1);

    if (noOfInvites <= 0) {
      history.push(route.DASHBOARD);
      onClose();
    }
  };

  const handleReject = (invitationID: string | undefined) => {
    rejectAccountSharingInvitation({ invitationId: invitationID }).then((res) => {
      if (!res.status || res.statusCode > 300) {
        dispatch(setError(res.message || data.error));
        return;
      }
      getUserEnergySetup().then((res) => {
        if (!res.status || res.statusCode >= 300) {
          console.error(res);
          return;
        }
        dispatch(setLocations(res?.content));
        return;
      });
    });
    setNoOfInvites(noOfInvites - 1);

    if (noOfInvites <= 0) {
      history.push(route.DASHBOARD);
      onClose();
    }
  };

  return (
    <>
      {locationList.map(
        (location, index) =>
          location?.invitationData?.invitationStatus === InvitationStatus.PENDING && (
            <div className="accept-invite" key={index} data-testid="accept-invite">
              <div className="accept-invite__content">
                <div className="accept-invite__content--data">
                  <span>{`${location.invitationData?.ownerFirstName || location.invitationData?.ownerEmail}`}</span>
                  <p className="description">{data.description}</p>
                </div>

                <div className="accept-invite__content--buttons">
                  <Button
                    className={BUTTON_COLORS.green}
                    type={BUTTON_TYPE.SUBMIT}
                    label={data.reject}
                    onClick={() => handleReject(location?.invitationData?.invitationId)}
                  />
                  <Button
                    className={`${BUTTON_COLORS.green} ${BUTTON_COLORS.filled}`}
                    type={BUTTON_TYPE.SUBMIT}
                    label={data.accept}
                    onClick={() => handleSubmit(location?.invitationData?.invitationId)}
                  />
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
};

export default AcceptInvite;
