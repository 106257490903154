/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { sharedRestrictedRoutes } from 'containers/appRouter';
import { Location } from 'types';

export const activateSharingAccessPopups = (pathname: string, activeLocation: Location) => {
  const { isGuestLocation, invitationData } = activeLocation;
  const accessTokens: { [key: string]: boolean | undefined } = {
    '/schedules': invitationData?.appAccess,
    '/dual-tariff': invitationData?.administratorAccess,
    '/agile-tariff': invitationData?.administratorAccess
  };
  const hasAccess = accessTokens[pathname];
  const isGuestAndOnRestrictedRoute =
    isGuestLocation && Object.values(sharedRestrictedRoutes).includes(pathname);
  const isRestricted = isGuestAndOnRestrictedRoute && !hasAccess;
  return {
    popUpCondition: isRestricted,
    jsonToLoad: isRestricted ? 'sharingpermissionslocations' : 'acceptinvite'
  };
};
