/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { isEmpty } from 'lodash-es';
import React, { FC, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { tracking } from 'shared/js';

export type Position = 'top' | 'bottom' | 'left' | 'right';

export interface IInfoPopUp {
  position?: Position;
  title?: string;
  text?: string;
  children?: ReactNode;
  content?: ReactNode;
  testId?: string;
  hideOnMouseLeave?: boolean;
  onHover?: boolean;
}

const InfoPopUp: FC<IInfoPopUp> = ({
  testId = 'info-pop-up',
  position = 'top',
  title,
  text,
  children,
  content,
  hideOnMouseLeave,
  onHover = false
}) => {
  const [active, setActive] = useState(false);
  const location = useLocation();

  if (isEmpty(children) || (isEmpty(title) && isEmpty(text))) {
    return <></>;
  }

  const handleShowPopup = () => {
    if (!active) {
      setActive(true);
      tracking({
        name: `InfoPopUp`,
        location: `${location.pathname}${location.hash}`,
        action: 'hover'
      });
    } else setActive(false);
  };

  return (
    <div
      className="info-popup__wrapper"
      onClick={handleShowPopup}
      onMouseEnter={onHover ? handleShowPopup : undefined}
      onMouseLeave={() => (hideOnMouseLeave || onHover ? setActive(false) : false)}
      data-testid={testId}
    >
      {children}
      <div className={`info-popup__box ${position} ${active ? 'active' : ''}`}>
        <div className="info-popup">
          <div className="info-popup__title">{title}</div>
          {text && <div className="info-popup__text">{text}</div>}
          <div className="info-popup__content"> {content}</div>
        </div>
      </div>
    </div>
  );
};

export default InfoPopUp;
