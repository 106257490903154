/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { DeviceType, DevicesType, ProductCodes } from 'constants/DeviceType';
import { CountryCodes } from 'constants/enums';
import { isEmpty } from 'lodash-es';
import { Address, Device, Hub, ILocationFormValues, Location } from 'types';

//ADDRESS
export const findActiveLocation = (arr: Location[], id: string) => {
  return arr.filter((location) => location?.energySetup?.id === id)[0];
};

export const processAddress = ({ addressLine1, city, country, postalCode }: Address) => {
  return [addressLine1, city, postalCode, country].filter((e) => e).join(', ');
};

export const trimValues = (values: ILocationFormValues) => {
  return {
    address: {
      addressLine1: values?.address?.addressLine1
        ? String(values?.address?.addressLine1).trim()
        : '',
      addressLine2: values?.address?.addressLine2
        ? String(values?.address?.addressLine2).trim()
        : '',
      city: values?.address?.city ? String(values?.address?.city).trim() : '',
      postalCode: values?.address?.postalCode ? String(values?.address?.postalCode).trim() : '',
      country: values?.address?.country ? String(values?.address?.country).trim() : '',
      countryCode: values?.address?.countryCode ? String(values?.address?.countryCode).trim() : '',
      fullAddress: values?.address?.fullAddress ? values.address.fullAddress : ''
    },
    nickname: values?.nickname ? String(values?.nickname).trim() : '',
    energyTariff: values?.energyTariff ? String(values?.energyTariff).trim() : '',
    otherElectricHeating: values?.otherElectricHeating
      ? String(values?.otherElectricHeating).trim()
      : '',
    otherRenewableGeneration: values?.otherRenewableGeneration
      ? String(values?.otherRenewableGeneration).trim()
      : '',
    tariffExpirationDate: values?.tariffExpirationDate,
    maxPowerOutput: values?.maxPowerOutput ? String(values?.maxPowerOutput).trim() : '',
    electricHeating: !isEmpty(values?.electricHeating)
      ? String(values?.electricHeating).trim()
      : '',
    renewableGeneration: !isEmpty(values?.renewableGeneration)
      ? String(values?.renewableGeneration).trim()
      : '',
    region: values?.address?.region ? String(values?.address?.region).trim() : ''
  };
};

export const extractFields = (address: Address) => {
  return {
    addressLine1: address?.addressLine1 || '',
    addressLine2: address?.addressLine2 || '',
    city: address?.city || '',
    country: address?.country || '',
    countryCode: address?.countryCode || '',
    postalCode: address?.postalCode || '',
    region: address?.region || ''
  };
};

//ADD ZERO TO NUMBER
export const addZero = (value: number) => (value < 10 ? `0${value}` : value);

//SHORTEN STRING
// @description: takes into account the word length and the '...'
export const shortenString = (value: string, len: number) => {
  if (String(value).length >= len) {
    return String(value)
      .substring(0, len - 2)
      .concat('...');
  } else {
    return value;
  }
};

// PRODUCT SOFTWARE VERSION FORMATTER
// @description: receives the serial number of device as string (number or N/A for offline devices).
// Outputs formatted version of the serial number placing decimal 3 chars from the end + V in front.
// Defaults to N/A.
export const softwareVersionFormatter = (version: string) => {
  const insert = '.';
  if (version === 'N/A' || version === '') {
    return 'N/A';
  } else {
    return 'V' + [version?.slice(0, -3), insert, version?.slice(-3)].join('');
  }
};

// CHECK IF POSTAL CODE IN GB IS NORTHERN IRELAND
// @description: receives country code and postal code.
// If country code is GB and postal code starts with BT, region is in Northern Ireland.
export const isNorthernIreland = (countryCode: string | null, postalCode: string | null) => {
  if (!postalCode) return;
  const regionCode = postalCode.substring(0, 2).toLowerCase();
  const northernIrelandCodes = ['bt'];

  return countryCode === CountryCodes.GB && northernIrelandCodes.includes(regionCode);
};

export const getDevicesFromLocation = (activeLocationId: string, hubList: Hub[]) => {
  const hubsFromAddress = hubList?.filter((hub) => hub.locationId === activeLocationId);
  const deviceList = hubsFromAddress?.map((hub) => hub?.devices);
  return deviceList?.flat(Infinity) as Device[];
};

export const isZappiGLO = (firmware?: string) => {
  if (!firmware) return false;

  const productCode = parseInt(firmware.split('S')[0]);
  return productCode === ProductCodes.ZappiGLO;
};

export const isEddiPlus = (firmware?: string) => {
  if (!firmware) return false;

  const productCode = parseInt(firmware.split('S')[0]);
  return productCode === ProductCodes.EddiPlus;
};

export const getDeviceType = (type: string, firmware?: string) => {
  const isZappiGLODevice =
    type === DeviceType.ZappiGLO || (type === DeviceType.Zappi && isZappiGLO(firmware));

  const isEddiPlusDevice =
    type === DeviceType.EddiPlus || (type === DeviceType.Eddi && isEddiPlus(firmware));

  if (isEddiPlusDevice) return 'eddi+';
  if (isZappiGLODevice) return 'zappi GLO';
  return type;
};

export const getImgDeviceType = (deviceType: string, firmware?: string): string => {
  if (deviceType === DeviceType.Zappi && isZappiGLO(firmware)) {
    return DeviceType.ZappiGLO;
  }

  if (deviceType === DeviceType.Eddi && isEddiPlus(firmware)) {
    return DeviceType.EddiPlus;
  }

  return deviceType;
};

export const getEWImgDeviceType = (deviceType: string, firmware: string): string => {
  const imageDeviceType = getImgDeviceType(deviceType, firmware);

  const isSpecialDeviceType =
    imageDeviceType === DeviceType.ZappiGLO || imageDeviceType === DeviceType.EddiPlus;

  return `img/${isSpecialDeviceType ? imageDeviceType : `warranty_${imageDeviceType}`}.png`;
};

export const getDeviceName = (
  deviceType: string,
  deviceName?: string | null,
  firmware?: string,
  data?: string
) => {
  const type = getDeviceType(deviceType, firmware);
  const isHub = deviceType === DevicesType.hub;

  const fallbackDeviceName = isHub ? data ?? DevicesType.gateway : type;

  return deviceName || fallbackDeviceName;
};

export const displayTechnicalFileButton = (
  deviceType: DevicesType,
  postalCode: string | null,
  countryCode: string | null
) => {
  const isSupportedDevice = deviceType === DevicesType.zappi;
  const isGB = countryCode === CountryCodes.GB;
  const isNotNorthernIreland = !isNorthernIreland(countryCode, postalCode);

  return isSupportedDevice && isGB && isNotNorthernIreland;
};

export const shouldDisplayZappiGLO = (email: string) => {
  const allowedEmails = process.env.REACT_APP_ALLOWED_EMAILS || '';
  const emails = allowedEmails?.split(',').map((email) => email.trim());

  const featureFlag = process.env.REACT_APP_RAPID_ZAPPI;

  return featureFlag === 'true' || emails.includes(email);
};
