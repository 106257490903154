/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Statusbar from 'components/elements/statusbar';
import Cookiebar from 'components/platform/cookiebar';
import Footer from 'components/platform/footer';
import Header from 'components/platform/header';
// Shared
import { useAppSelector } from 'customHooks';
import { FC, ReactNode } from 'react';
import { connect } from 'react-redux';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const { isLoading } = useAppSelector((store) => store.site);

  return (
    <div className={`${isLoading ? 'loading' : ''}`} data-testid={'layout'}>
      {!isLoading && <Statusbar isPopup={false} />}
      <div className="layout">
        <Header />
        <main className="content">{children}</main>
        <Cookiebar />
        <Footer />
      </div>
    </div>
  );
};

export default connect()(Layout);
