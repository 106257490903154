/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { BUTTON_TYPE } from 'components/elements/button/consts';
import { FC } from 'react';

interface ButtonProps {
  label?: string;
  type?: BUTTON_TYPE;
  className?: string;
  isDisabled?: boolean;
  onClick?: () => void;
  testId?: string;
}

const Button: FC<ButtonProps> = ({
  label = '',
  type = BUTTON_TYPE.BUTTON,
  onClick,
  className = '',
  isDisabled,
  testId = 'button'
}) => {
  return (
    <button
      className={`button-p ${className} ${isDisabled ? 'disabled' : ''}`}
      type={type}
      onClick={() => onClick && onClick()}
      disabled={isDisabled}
      aria-label="details"
      data-testid={testId}
    >
      {label}
    </button>
  );
};

export default Button;
