/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { SUBMENU_HASH } from './menu.consts';
import { SubmenuData } from './menu.types';

export const getSubmenuValues = (data?: SubmenuData) => {
  return {
    energyUsage: {
      consumed: { label: data?.energyUsage?.consumed, hash: SUBMENU_HASH.energyUsage.consumed },
      deviceUsage: {
        label: data?.energyUsage?.deviceUsage,
        hash: SUBMENU_HASH.energyUsage.deviceUsage
      },
      batteryUsage: {
        label: data?.energyUsage?.batteryUsage,
        hash: SUBMENU_HASH.energyUsage.batteryUsage
      },
      chargingSessions: {
        label: data?.energyUsage?.chargingSessions,
        hash: SUBMENU_HASH.energyUsage.chargingSessions
      }
    },
    location: {
      locationSettings: {
        label: data?.location?.locationSettings,
        hash: SUBMENU_HASH.location.locationSettings
      },
      products: { label: data?.location?.products, hash: SUBMENU_HASH.location.products },
      accessManagement: {
        label: data?.location?.accessManagement,
        hash: SUBMENU_HASH.location.accessManagement
      },
      sharing: { label: data?.location?.sharing, hash: SUBMENU_HASH.location.sharing }
    },
    account: {
      settings: { label: data?.account?.settings, hash: SUBMENU_HASH.account.settings },
      vehicles: { label: data?.account?.vehicles, hash: SUBMENU_HASH.account.vehicles },
      preferences: { label: data?.account?.preferences, hash: SUBMENU_HASH.account.preferences },
      accessManagement: {
        label: data?.account?.integrations,
        hash: SUBMENU_HASH.account.integrations
      },
      publicApi: { label: data?.account?.publicApi, hash: SUBMENU_HASH.account.publicApi }
    }
  };
};
