/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import CryptoJS from 'crypto-js';
import { S } from 'shared/js';

export const encryptString = (str: string | object) => {
  const data = typeof str === 'object' ? JSON.stringify(str) : str;
  return CryptoJS.AES.encrypt(data, S).toString();
};

export const decryptString = (str: string) => {
  const cyp = CryptoJS.AES.decrypt(str, S);
  return cyp.toString(CryptoJS.enc.Utf8);
};
