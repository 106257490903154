/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { activateSharingAccessPopups } from 'shared/js';

import { useAppSelector } from './useAppSelector';

export const usePopups = () => {
  const { pathname } = useLocation();

  /* istanbul ignore next*/
  const { activeLocation } = useAppSelector((state) => state.location);
  const sharingPopup = useMemo(() => {
    return activateSharingAccessPopups(pathname, activeLocation);
  }, [pathname, activeLocation]);

  return sharingPopup;
};
