/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import {
  IMonthTranslation,
  generateOptionList,
  getFormattedDate,
  getTime,
  isSameDay
} from 'shared/js';

export const generateHeatersOptionList = (arr: string[], data: string) => {
  const heaters = arr.filter((heater) => heater && !String(heater).includes('Disabled'));

  if (heaters?.length >= 2) {
    heaters.push(data);
  }

  return generateOptionList(heaters);
};

export const getNextCharge = (from: string, to: string, data: IMonthTranslation) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const fromTime = getTime(fromDate);
  const fromFormattedDate = isSameDay(fromDate, toDate)
    ? ''
    : ` ${getFormattedDate(fromDate, data)}`;
  const toTime = getTime(toDate);
  const toFormattedDate = getFormattedDate(toDate, data);

  return `${fromTime}${fromFormattedDate} - ${toTime} ${toFormattedDate}`;
};
