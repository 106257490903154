/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tariffs } from 'types';

const initialState: Tariffs = {
  tariffs: {},
  providers: {},
  providerList: []
};

const tariffsSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {
    setAllTariffs(state, { payload }: PayloadAction<Tariffs>) {
      state.tariffs = payload.tariffs;
      state.providers = payload.providers;
      state.providerList = payload.providerList;
    }
  }
});

export const { setAllTariffs } = tariffsSlice?.actions;
export const tariffs = tariffsSlice.reducer;
