/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
interface IACCOUNT_TYPE {
  [key: string]: number;
}

export const ACCOUNT_TYPE: IACCOUNT_TYPE = Object.freeze({
  normal: 0,
  google: 1,
  apple: 2,
  facebook: 3,
  microsoft: 4
});

//LANGUAGE
export const LANGUAGES = Object.freeze({
  EN_GB: 'en-GB',
  FR: 'fr-FR',
  NL: 'nl-NL',
  DE: 'de'
});

//COUNTRIES
export const COUNTRY_MAP_LIST = Object.freeze({
  GB: 'UK'
});

//CURRENCY
export const CURRENCY = Object.freeze({
  GBP: 'GBP',
  EUR: 'EUR'
});

//DATES
export const SHORT_MONTHS = Object.freeze([
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
]);

export const DATE_FORMATS = Object.freeze({
  DD_MM_YYYY: 'DD/MM/YYYY',
  MM_DD_YYYY: 'MM/DD/YYYY'
});

export const DAYS = Object.freeze({
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0
});

export const FL = Object.freeze({
  SERVER: 's',
  DATABASE: 'd',
  APP: 'a'
});

//DEVICE STATUS
export const STATUS = {
  none: 'inactive',
  active: 'active',
  new: 'new',
  inactive: 'inactive',
  accepted: 'active',
  pending: 'new',
  deleted: 'deleted'
};

//DISTANCE UNITS
export const DISTANCE_UNITS = Object.freeze({
  MILES: 'mi',
  KILOMETERS: 'km'
});

export const Distance = Object.freeze({
  mi: 'Miles',
  km: 'Kilometers'
});

//USER TITLES
export const USER_TITLES = Object.freeze({
  MR: 'Mr',
  MRS: 'Mrs',
  MISS: 'Miss',
  DR: 'Dr',
  Other: 'Other'
});

//VEHICLE OWNERSHIP
export const VEHICLE_OWNERSHIP = Object.freeze({
  Personal: 'Personal',
  Fleet: 'Fleet'
});

export const NONE_OPTION = 'None';

//WEATHER DATA TYPES
export const WEATHER_DATA_TYPES = {
  OVERVIEW: 'overview',
  SOLAR_RADIATION: 'solarRadiation'
};
