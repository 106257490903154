/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SharedAccount, SharedAccounts } from 'types';

const initialState: SharedAccounts = {
  sharedAccountsList: []
};

const sharedAccountsSlice = createSlice({
  name: 'sharedAccounts',
  initialState,
  reducers: {
    setSharedAccountsUpdate(state, action: PayloadAction<Array<SharedAccount>>) {
      state.sharedAccountsList = action.payload;
    },
    updateSharedAccount(state, action: PayloadAction<SharedAccount>) {
      const { payload } = action;
      state.sharedAccountsList = state.sharedAccountsList.map((invitation) =>
        payload?.id === invitation.id ? { ...payload } : invitation
      );
    },
    addSharedAccountsUpdate(state, action: PayloadAction<SharedAccount>) {
      state.sharedAccountsList.push(action.payload);
    }
  }
});

export const { setSharedAccountsUpdate, updateSharedAccount, addSharedAccountsUpdate } =
  sharedAccountsSlice.actions;
export const sharedAccounts = sharedAccountsSlice.reducer;
