/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash-es';
import { Schedules, ScheduleState, SchedulesState } from 'types';

const initialState: SchedulesState = {
  scheduleList: {}
};

const schedulesSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    setSchedules(state, action: PayloadAction<Schedules>) {
      const { payload } = action;
      if (!isEmpty(payload)) {
        state.scheduleList = payload;
      }
    },
    setScheduleState(state, action: PayloadAction<ScheduleState>) {
      const { payload } = action;
      const activeHubId = state.scheduleList[payload?.activeHubId];
      if (!isEmpty(payload)) {
        if (state.scheduleList !== null) {
          const index = activeHubId?.devices.findIndex((dev) => dev.deviceId === payload.deviceId);
          activeHubId.devices[index] = {
            ...activeHubId.devices[index],
            [payload.propertyType]: payload.isActive
          };
        }
      }
    },
    setChargeFromGridState(state, action: PayloadAction<ScheduleState>) {
      const { payload } = action;
      const activeHubId = state.scheduleList[payload?.activeHubId];
      if (!isEmpty(payload)) {
        if (state.scheduleList !== null) {
          const index = activeHubId?.devices.findIndex((dev) => dev.deviceId === payload.deviceId);
          activeHubId.devices[index] = {
            ...activeHubId.devices[index],
            [payload.propertyType]: payload.isActive
          };
        }
      }
    }
  }
});

export const { setSchedules, setScheduleState, setChargeFromGridState } = schedulesSlice?.actions;
export const schedules = schedulesSlice.reducer;
