/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { Device, Hub } from 'types';

export const processProductCode = (str: string): [number, number] => {
  const productCodeValue = str?.split('S')[0];
  const productFwVersion = str?.split('S')[1];

  return [Number(productCodeValue), Number(productFwVersion)];
};

export const updateDeviceStatus = (device: Device, payload: { [key: string]: number }) => {
  if (payload[device?.serialNumber]) {
    return { ...device, status: payload[device.serialNumber] };
  }
  return device;
};

export const updateHubStatus = (hub: Hub, payload: { [key: string]: number }) => {
  if (!hub) return hub;

  const updatedHub = { ...hub };

  if (payload[hub.serialNo]) {
    updatedHub.status = payload[hub.serialNo];
  }

  if (hub.devices) {
    updatedHub.devices = hub.devices.map((device) => updateDeviceStatus(device, payload));
  }

  return updatedHub;
};

export const EMPTY_ACTIVE_HUB: Hub = {
  extendedWarrantyExpirationDate: '',
  firmware: '',
  id: '',
  isEligibleForEW: false,
  isExtendedWarranty: false,
  isVHub: false,
  nickName: null,
  registrationCode: null,
  serialNo: '',
  standardWarrantyExpirationDate: '',
  status: 1,
  timeZoneRegion: '',
  locationId: '',
  devices: []
};
