/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
export const MENU_INITIAL_DATA = {
  dashboard: '',
  mytariff: '',
  myschedules: '',
  devices: '',
  graphs: '',
  location: '',
  dataReports: '',
  notifications: '',
  logout: '',
  account: '',
  copyrightText: '',
  submenuTabs: {
    energyUsage: {
      consumed: '',
      deviceUsage: '',
      batteryUsage: '',
      chargingSessions: ''
    },
    location: {
      locationSettings: '',
      products: '',
      accessManagement: '',
      sharing: ''
    },
    account: {
      settings: '',
      vehicles: '',
      preferences: '',
      integrations: '',
      publicApi: ''
    }
  }
};

export const SUBMENU_HASH = {
  energyUsage: {
    consumed: '#consumed',
    deviceUsage: '#usage',
    batteryUsage: '#battery',
    chargingSessions: '#sessions'
  },
  location: {
    locationSettings: '#location',
    products: '#products',
    accessManagement: '#access-management',
    sharing: '#sharing'
  },
  account: {
    settings: '#account',
    vehicles: '#vehicles',
    preferences: '#preferences',
    integrations: '#integrations',
    publicApi: '#public-api'
  }
};
