/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import SelectOption from 'components/elements/select';
import Textbox from 'components/elements/textbox';
import { countries } from 'countries-list';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { SingleValue } from 'react-select';

import { ADDRESS_FIELDS } from './constants';

const countriesNames = Object.values(countries)
  .map((country) => ({
    value: country.name,
    label: country.name
  }))
  .sort((a, b) => {
    let a1 = a.label;
    let b1 = b.label;

    if (a1[0] === 'Å') {
      a1 = a1.replace('Å', 'A');
    }

    if (b1[0] === 'Å') {
      b1 = b1.replace('Å', 'A');
    }

    return a1 < b1 ? -1 : 1;
  });

const getCountryCode = (countryName: string) =>
  (Object.keys(countries) as Array<keyof typeof countries>).find(
    (countryCode) => countries[countryCode].name === countryName
  );

export interface IManual {
  labels: {
    [x: string]: string;
  };
  title?: string;
  setFullAddress: (x: { [p: string]: string }) => void;
  onCountryChange?: () => void;
}
const Manual: FC<IManual> = (props) => {
  const { labels, title, setFullAddress, onCountryChange } = props;
  const { values, setFieldValue }: { [x: string]: any } = useFormikContext();
  const handleCountryFieldChange = (option: SingleValue<{ label: string; value: string }>) => {
    if (option) {
      const countryCode = getCountryCode(option.value);
      setFieldValue(`address.${ADDRESS_FIELDS.countryCode}`, countryCode);
    }

    if (typeof onCountryChange === 'function') {
      onCountryChange();
    }
  };
  const handleFieldChange = (event: { target: { name: string; value: string } }) => {
    const { name, value } = event.target;
    const fieldName = name.split('.').pop() as string;
    setFullAddress({ [fieldName]: value });
  };

  return (
    <div className="form-address-manual" data-testid="manual">
      {title && <h5>{title}</h5>}
      <Textbox
        name={`address.${ADDRESS_FIELDS.line1}`}
        id={`address.${ADDRESS_FIELDS.line1}`}
        label={labels?.firstLineLabel}
        onChange={handleFieldChange}
      />
      <Textbox
        name={`address.${ADDRESS_FIELDS.line2}`}
        id={`address.${ADDRESS_FIELDS.line2}`}
        label={labels?.secondLineLabel}
      />
      <Textbox
        name={`address.${ADDRESS_FIELDS.city}`}
        id={`address.${ADDRESS_FIELDS.city}`}
        label={labels?.cityLabel}
        onChange={handleFieldChange}
      />
      <Textbox
        name={`address.${ADDRESS_FIELDS.region}`}
        id={`address.${ADDRESS_FIELDS.region}`}
        label={labels?.countyLabel}
      />
      <Textbox
        name={`address.${ADDRESS_FIELDS.postalCode}`}
        id={`address.${ADDRESS_FIELDS.postalCode}`}
        label={labels?.postcodeLabel}
        onChange={handleFieldChange}
      />
      <Textbox
        name={`address.${ADDRESS_FIELDS.countryCode}`}
        id={`address.${ADDRESS_FIELDS.countryCode}`}
        hidden
      />
      <SelectOption
        name={`address.${ADDRESS_FIELDS.country}`}
        id={`address.${ADDRESS_FIELDS.country}`}
        label={labels?.countryLabel}
        optionList={countriesNames}
        onChange={handleCountryFieldChange}
        defaultValue={{ label: values.address.country, value: `address.${ADDRESS_FIELDS.country}` }}
        isSearchable
      />
    </div>
  );
};

export default Manual;
