/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Button from 'components/elements/button';
import { BUTTON_COLORS } from 'components/elements/button/consts';
import { FC } from 'react';
import { tracking } from 'shared/js';
import { ILocationFormValues } from 'types';

interface LibbiTariffWarningProps {
  values: ILocationFormValues;
  handleSubmit: (values: ILocationFormValues) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  onClose?: () => void;
  data: {
    description: string;
    cancelBtn: string;
    confirmBtn: string;
  };
}

const LibbiTariffWarning: FC<LibbiTariffWarningProps> = ({
  data,
  onClose,
  handleSubmit,
  values,
  setSubmitting
}) => {
  const handleSave = () => {
    handleSubmit(values);

    tracking({
      name: 'Confirm button',
      location: 'Location / Edit Location / Libbi Tariff Warning',
      action: 'click'
    });
  };

  const handleCancel = () => {
    onClose && onClose();
    setSubmitting(false);

    tracking({
      name: 'Cancel button',
      location: 'Location / Edit Location / Libbi Tariff Warning',
      action: 'click'
    });
  };

  return (
    <div className="libbi-tariff-warning" data-testid="libbi-tariff-warning">
      <div className="libbi-tariff-warning__description">
        <span>{data.description}</span>
      </div>
      <div className="libbi-tariff-warning__buttons">
        <Button label={data.cancelBtn} className={BUTTON_COLORS.green} onClick={handleCancel} />
        <Button
          label={data.confirmBtn}
          className={`${BUTTON_COLORS.green} ${BUTTON_COLORS.filled}`}
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export default LibbiTariffWarning;
