/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { Location } from 'types';

export interface IAcceptInviteData {
  description?: string;
  reject?: string;
  accept?: string;
  error: string;
}

export interface AcceptInviteProps {
  onClose: () => void;
  locationList: Location[];
}

export const acceptInviteDataInitialValues = {
  description: '',
  reject: '',
  accept: '',
  error: ''
};
