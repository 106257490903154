/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { Field, FieldInputProps, useFormikContext } from 'formik';

interface IMultiSelect {
  options?: Array<{ id: string; label: string; name: string; description?: string }>;
  name: string;
  color?: string;
  label: string;
}

const MultiSelect = ({ options, name, color, label }: IMultiSelect) => {
  const { setFieldValue } = useFormikContext();

  const handleChange = (field: FieldInputProps<string>, option: { id: string }) => {
    const set = new Set(field.value);

    if (set.has(option.id)) {
      set.delete(option.id);
    } else {
      set.add(option.id);
    }

    setFieldValue(name, Array.from(set));

    return;
  };

  return (
    <div className="form-multiselect" data-testid="form-multiselect">
      {label && <label>{label}</label>}
      {options?.map((option, idx) => (
        <Field name={name} key={idx}>
          {({ field }: { field: FieldInputProps<string> }) => (
            <div
              className={`form-checkbox ${color || ''} ${field?.value?.includes(option.id) ? 'checked' : ''}`}
            >
              <input
                id={option?.id}
                {...field}
                type="checkbox"
                checked={field?.value?.includes(option.id)}
                onChange={() => handleChange(field, option)}
                data-testid="multiselect-input"
              />
              <label
                htmlFor={option.id}
                className={`${option.description ? 'has-description' : ''}`}
              >
                <span>{option.label}</span>
                {option.description && <span>{option.description}</span>}
              </label>
            </div>
          )}
        </Field>
      ))}
    </div>
  );
};

export default MultiSelect;
