/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DualTariff, SetDualEconomyPricesPayload, SetEmptyDualTariffSchemesPayload } from 'types';

const initialState: DualTariff = {
  dualEconomyPrices: {},
  emptyDualTariffSchemes: {}
};

const dualTariffSlice = createSlice({
  name: 'dualTariff',
  initialState,
  reducers: {
    setDualEconomyPrices(state, { payload }: PayloadAction<SetDualEconomyPricesPayload>) {
      state.dualEconomyPrices = {
        ...state.dualEconomyPrices,
        [payload.hubId]: payload.dualEconomyPrices
      };
    },
    setEmptyDualTariffSchemes(state, { payload }: PayloadAction<SetEmptyDualTariffSchemesPayload>) {
      state.emptyDualTariffSchemes = {
        ...state.emptyDualTariffSchemes,
        [payload.hubId]: payload.emptyDualTariffSchemes
      };
    }
  }
});

export const { setDualEconomyPrices, setEmptyDualTariffSchemes } = dualTariffSlice.actions;
export const dualTariff = dualTariffSlice.reducer;
