/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { isEmpty } from 'lodash-es';
import { DISTANCE_UNITS } from 'shared/js';
import { GeneralVehicleData } from 'types';

type Keys = keyof typeof DISTANCE_UNITS;
type DistanceUnits = (typeof DISTANCE_UNITS)[Keys];

export const processDistanceIn = (type: string, value?: number) => {
  if (value) {
    const kMRange = value / 1.609;
    return type === DISTANCE_UNITS.MILES
      ? parseFloat(kMRange.toFixed(2))
      : parseFloat(value.toFixed(2));
  }
  return 0;
};

export const processDistanceOut = (type: DistanceUnits, value: number) => {
  if (value) {
    const milesRange = value * 1.609;
    return type === DISTANCE_UNITS.MILES
      ? parseFloat(milesRange.toFixed(2))
      : parseFloat(value.toFixed(2));
  }
  return 0;
};

export const processLabelDistance = (type: string, data: GeneralVehicleData | undefined) => {
  if (!isEmpty(data) && data?.distances !== undefined) {
    return type === DISTANCE_UNITS.MILES ? data.distances['MILES'] : data.distances['KILOMETERS'];
  }
  return '';
};

export const distanceFormated = (isMetric: boolean) =>
  isMetric ? DISTANCE_UNITS.KILOMETERS : DISTANCE_UNITS.MILES;
