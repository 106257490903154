/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Button from 'components/elements/button';
import { BUTTON_COLORS } from 'components/elements/button/consts';
import { FC } from 'react';

interface IInfoMessage {
  onClose?: () => void;
  testId?: string;
}

const InfoMessage: FC<IInfoMessage> = ({ onClose, testId = 'info-message' }) => {
  return (
    <div className="info-message" data-testid={testId}>
      <Button className={BUTTON_COLORS.pink} onClick={onClose} label={'Ok'} />
    </div>
  );
};

export default InfoMessage;
