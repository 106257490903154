/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import IconButton from 'components/elements/iconButton';
import HeaderMenu from 'components/platform/headerMenu';
import Logo from 'components/platform/logo';
import { route } from 'containers/appRouter';
import { toggleMobileDrawer, setMenuVisibility } from 'containers/appStore';
import { useAppDispatch, useAppSelector } from 'customHooks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SIGNED_IN, SOCIAL_SIGNED_IN } from 'shared/js';

const renderBasicHeader = () => (
  <header className="header login" data-testid="basic-header">
    <Logo className="solo" />
  </header>
);

const renderRegistrationHeader = () => (
  <header className="header registration" data-testid="registration-header">
    <Logo />
    <HeaderMenu />
  </header>
);

const renderPortalHeader = (dispatchMobileDrawer: () => void, isMenuVisible: boolean) => {
  return (
    <header className="header portal" data-testid="portal-header">
      <Logo />
      {isMenuVisible ? (
        <IconButton icon="menu-drawer-icon" color="glass" onClick={dispatchMobileDrawer} />
      ) : (
        ''
      )}
      <HeaderMenu />
    </header>
  );
};

const Header = () => {
  const dispatch = useAppDispatch();
  const pathname = useLocation().pathname;
  const { mobileDrawer, isMenuVisible } = useAppSelector((store) => store.site);
  const { authState } = useAppSelector((store) => store.userDetails);

  const dispatchMobileDrawer = () => {
    dispatch(toggleMobileDrawer(!mobileDrawer));
  };

  useEffect(() => {
    if (authState === SIGNED_IN || authState === SOCIAL_SIGNED_IN) {
      dispatch(setMenuVisibility(true));
    }
    // eslint-disable-next-line
  }, [authState]);

  switch (true) {
    case pathname === route.LOGIN || pathname === route.REGISTRATION:
      return <>{renderBasicHeader()}</>;
    case pathname === route.VERIFICATION || pathname === route.SOCIAL_REGISTRATION:
      return <>{renderRegistrationHeader()}</>;
    default:
      return <>{renderPortalHeader(dispatchMobileDrawer, isMenuVisible)}</>;
  }
};

export default Header;
