/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { Amplify } from 'aws-amplify';
import awsconfig from 'awsExports';

/* All routing within the app for any type of state */
import { AppRouter } from 'containers/appRouter';

/* All centralized data for macro and micro components */
import { AppStore } from 'containers/appStore';
import { createRoot } from 'react-dom/client';

/* GA Tracking Configuration */
import { configGoogleTag } from 'shared/js';

Amplify.configure(awsconfig);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AppStore>
    <AppRouter />
  </AppStore>
);

configGoogleTag();
