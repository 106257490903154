/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
export const signedInUserRoutes = Object.freeze({
  DASHBOARD: '/',
  ACCOUNT: '/account',
  LOCATION: '/location',
  AGILE_TARIFF: '/agile-tariff',
  DUAL_TARIFF: '/dual-tariff',
  SCHEDULES: '/schedules',
  DEVICES: '/devices',
  ENERGY_USAGE: '/energy',
  NOTIFICATIONS: '/notifications',
  LOGOUT: '/logout',
  EXTENDED_WARRANTY: '/extended-warranty',
  EXTENDED_WARRANTY_CANCEL: '/extended-warranty-cancel',
  EW_SUCCESS: '/success',
  EW_CANCEL: '/cancel',
  DATA_REPORTS: '/data-reports'
});

export const defaultRoutes = Object.freeze({
  LOGIN: '/login',
  REGISTRATION: '/registration',
  SOCIAL_REGISTRATION: '/socialregistration',
  FORGOT_PASSWORD: '/reset',
  VERIFICATION: '/verification'
});

export const sharedRestrictedRoutes = {
  DUAL_TARIFF: '/dual-tariff',
  AGILE_TARIFF: '/agile-tariff',
  SCHEDULES: '/schedules'
};

export const route = Object.freeze({
  ...signedInUserRoutes,
  ...defaultRoutes
});

export const getConnectedRoute = (loc: string) => {
  const routes = Object.values(signedInUserRoutes);
  return routes.map((item) => item === loc).some((element) => element === true);
};
