/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Icon from 'components/elements/icon';
import { route, getConnectedRoute } from 'containers/appRouter';
import { useAppSelector } from 'customHooks';
import { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

interface LogoProps {
  className?: string;
}

const Logo: FC<LogoProps> = ({ className }) => {
  const location = useLocation().pathname;
  const isContent = getConnectedRoute(location);

  const store = useAppSelector((state) => state);
  const { userDetails } = store;
  const { authState } = userDetails;

  return (
    <div className={`logo ${className || ''}`} data-testid="logo">
      <Link to={authState === 'signedIn' ? route.DASHBOARD : route.LOGIN}>
        <Icon name="logoheader" size={20} />
        <span className="logo__name">{`myenergi ${isContent ? 'account' : ''}`}</span>
      </Link>
    </div>
  );
};

export default Logo;
