/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
export const WS_TO_KWH_MINUTE_GRAPH = 60 * 1000;
export const WS_TO_KWH_HOURLY_GRAPH = 60 * 60 * 1000;
export const SMOOTH_RATE = 2;
export const FILTER_ELEMENTS = 5;
export const BOUNDARY = FILTER_ELEMENTS - 1;

export enum EnergyUsage {
  GRID = 'Imported',
  GEN = 'Consumed',
  EXP = 'Exported',
  HOME = 'Home'
}

export enum BatteryUsage {
  BCP = 'charge',
  BDP = 'discharge',
  SOC = 'soc'
}

export const TabsType = {
  consumed: 'consumedSets',
  usage: 'usageSets',
  battery: 'batterySets',
  chargingSessions: 'chargingSessions'
};
