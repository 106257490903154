/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import {
  generalUserDetails,
  getDeletedHubsAndDevices,
  getUserEnergySetup,
  getUserHubsAndDevices,
  getUserVehicles,
  updateDevices
} from 'api/methods';
import { loadText } from 'api/requests';
import { getCookie } from 'components/platform/cookiebar/helper';
import { getCodeFromLanguage, setLocale } from 'components/sitePreferences/helpers';
import { InvitationStatus } from 'constants/enums';
import { getConnectedRoute } from 'containers/appRouter/route';
import {
  setPersonalInfo,
  setUserVehicles,
  processAllPersonalInfo,
  processAllVehicleData,
  setLocations,
  setSharedUserHubs,
  processDeviceList,
  setDevicesAndHubList,
  setActiveLocation,
  setActiveLocationId,
  setIsAllDataReady,
  setError,
  setDeletedDevicesAndHubList,
  setPreferences,
  updateStatus
} from 'containers/appStore';
import { useAppSelector, useAppDispatch } from 'customHooks';
import { isEmpty } from 'lodash-es';
import { useEffect, useState, FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { SIGNED_IN, findActiveLocation } from 'shared/js';

interface DataLayerProps {
  children: ReactNode;
}

const DataLayer: FC<DataLayerProps> = ({ children }) => {
  const store = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const pathname = useLocation().pathname;
  const isConnected = getConnectedRoute(pathname);

  const { authState } = store.userDetails;
  const [data, setData] = useState<{ somethingWrong: string }>({ somethingWrong: '' });
  const [locationDataReady, setLocationDataReady] = useState<boolean>(false);
  const [vehicleDataReady, setVehicleDataReady] = useState<boolean>(false);
  const [userDataReady, setUserDataReady] = useState<boolean>(false);
  const [productsDataReady, setProductsDataReady] = useState<boolean>(false);

  const invitationData = store?.location?.activeLocation?.invitationData;
  const invitationId =
    invitationData?.invitationStatus === InvitationStatus.ACCEPTED
      ? invitationData?.invitationId
      : undefined;
  const { language } = store.userDetails.personalInfo.preferences;
  const { isAllDataReady } = store.site;

  const activeLocation = () => {
    return getCookie('activeLocation');
  };

  const activeLocationId = store?.location?.activeLocation?.energySetup?.id || activeLocation();

  const supportedLanguages = ['nl-NL', 'de', 'fr-FR'];
  const userLanguage = getCookie('preferedLanguage') || getCodeFromLanguage[navigator.language];
  const isUserLanguageSupported = supportedLanguages.includes(userLanguage);

  useEffect(() => {
    if (!isUserLanguageSupported) {
      const objPreferences = {
        ...store.userDetails.personalInfo.preferences,
        language: 'en-GB'
      };
      dispatch(setPreferences(objPreferences));
    }

    // eslint-disable-next-line
  }, [language, userLanguage, isAllDataReady]);

  useEffect(() => {
    loadText('errormessage', language).then((res) => setData(res?.data));
    setLocale(language);
  }, [language]);

  useEffect(() => {
    if (authState === SIGNED_IN) {
      getUserEnergySetup()
        .then((res) => {
          if (!res.status || res.statusCode >= 300) {
            dispatch(setError(res.message || data.somethingWrong));
            return;
          }
          dispatch(setLocations(res?.content));

          if (activeLocation()) {
            const getActiveLocation = findActiveLocation(res?.content, activeLocationId!);

            if (getActiveLocation) {
              dispatch(setActiveLocation(getActiveLocation));
            }
            dispatch(setLocations(res?.content));
          }
        })
        .finally(() => setLocationDataReady(true));

      getUserVehicles()
        .then((res) => {
          if (!res.status || res.statusCode >= 300) {
            dispatch(setError(res.message || data.somethingWrong));
            return;
          }
          dispatch(setUserVehicles(processAllVehicleData(res?.content)));
        })
        .finally(() => setVehicleDataReady(true));

      generalUserDetails()
        .then((res) => {
          if (!res.status || res.statusCode >= 300) {
            dispatch(setError(res.message || data.somethingWrong));
            return;
          }
          if (!isEmpty(res?.content)) {
            dispatch(setPersonalInfo(processAllPersonalInfo(res?.content)));
          }
        })
        .finally(() => setUserDataReady(true));
    }
    // eslint-disable-next-line
  }, [authState]);

  useEffect(() => {
    locationDataReady &&
      dispatch(setActiveLocationId(store?.location?.activeLocation?.energySetup?.id));

    if (locationDataReady) {
      getUserHubsAndDevices({ invitationId })
        .then((res) => {
          if (!res.status || res.statusCode >= 300) {
            dispatch(setError(res.message || data.somethingWrong));
            return;
          }
          setSharedUserHubs(res?.content);
          dispatch(setDevicesAndHubList(processDeviceList(res?.content)));
        })
        .finally(() => setProductsDataReady(true));

      getDeletedHubsAndDevices({ invitationId }).then((res) => {
        if (!res.status || res.statusCode >= 300) {
          dispatch(setError(res.message || data.somethingWrong));
          return;
        }

        const response = processDeviceList(res?.content);

        dispatch(
          setDeletedDevicesAndHubList({
            deletedDevices: response?.devicesList,
            deletedHubs: response?.hubList,
            deletedOfflineDevices: response?.offlineProducts
          })
        );
      });
    }
    // eslint-disable-next-line
  }, [locationDataReady]);

  useEffect(() => {
    vehicleDataReady && userDataReady && productsDataReady && dispatch(setIsAllDataReady(true));
    // eslint-disable-next-line
  }, [vehicleDataReady, userDataReady, productsDataReady]);

  useEffect(() => {
    isAllDataReady &&
      updateDevices({ invitationId }).then((res) => {
        if (!res.status || res.statusCode >= 300) {
          dispatch(setError(res.message || data.somethingWrong));
          return;
        }
        dispatch(updateStatus(res.content));
      });

    // eslint-disable-next-line
  }, [isAllDataReady]);

  return (
    <div className={`page-layout ${isConnected ? 'grid' : ''}`} data-testid="page-layout">
      {children}
    </div>
  );
};
export default DataLayer;
