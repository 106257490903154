/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { CC_GA } from 'components/platform/cookiebar/consts';
import { getCookie } from 'components/platform/cookiebar/helper';
import { trackingProps } from 'testUtils';

export const tracking = (props: trackingProps) => {
  if (process.env.REACT_APP_GTAG && getCookie(CC_GA)) {
    const dataLayer = window.dataLayer || [];
    dataLayer.push(props);
  }
};
