/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
const DropdownArrow = () => {
  return (
    <div data-testid="dropdownarrow">
      <svg className="form-select__arrow-down" width="12" height="8" viewBox="0 0 12 8">
        <path
          fill="none"
          fillRule="evenodd"
          stroke="#FF008C"
          strokeWidth="2"
          d="M543.743 25.743L543.743 32.743 536.743 32.743"
          transform="rotate(45 307.042 -629.352)"
        />
      </svg>
    </div>
  );
};

export default DropdownArrow;
