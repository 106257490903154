/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { Dayjs } from 'dayjs';
import { FC } from 'react';

interface FormErrorProps {
  forcedShow?: string | null;
  className?: string | null;
  meta?: {
    error?: string;
    initialError?: string;
    initialTouched?: boolean;
    initialValue?: string | number | Dayjs;
    touched: boolean;
    value?: string | number | Dayjs;
  };
}

const FormError: FC<FormErrorProps> = ({ forcedShow, meta, className }) => {
  if (forcedShow) {
    return <div className={`form-error ${className}`}>{forcedShow}</div>;
  }
  if (meta?.touched && meta?.error) {
    return <div className={`form-error ${className}`}>{meta?.error}</div>;
  }

  return <></>;
};

export default FormError;
