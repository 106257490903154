/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setCookie } from 'components/platform/cookiebar/helper';
import { isEmpty } from 'lodash-es';
import { EMPTY_ACTIVE_HUB, updateDeviceStatus, updateHubStatus } from 'shared/js/helpers/products';
import {
  Products,
  SetDevicesAndHubListPayload,
  OcppSettings,
  SetDeletedDevicesAndHubList,
  OcppTransactionStatus
} from 'types';

const initialState: Products = {
  hubList: [],
  offlineProducts: [],
  deviceHeaters: {},
  devicesList: [],
  deletedDevicesAndHubList: {
    deletedHubs: [],
    deletedDevices: [],
    deletedOfflineDevices: []
  },
  activeHub: {
    extendedWarrantyExpirationDate: '',
    firmware: '',
    id: '',
    isEligibleForEW: false,
    isExtendedWarranty: false,
    isVHub: false,
    nickName: null,
    registrationCode: null,
    registrationStartDate: '',
    serialNo: '',
    standardWarrantyExpirationDate: '',
    status: 1,
    timeZoneRegion: '',
    locationId: '',
    devices: []
  },
  activeLocationId: '',
  lastGeneratedApiKey: null,
  ocppSettings: null,
  ocppTransactionStatus: null,
  ocppTransactionStatusError: false
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setLastGeneratedApiKey(state, { payload }: PayloadAction<string | null>) {
      state.lastGeneratedApiKey = payload;
    },
    setDevicesAndHubList(state, { payload }: PayloadAction<SetDevicesAndHubListPayload>) {
      state.devicesList = payload.devicesList;
      state.hubList = payload.hubList;
      state.deviceHeaters = payload.deviceHeaters;
      state.offlineProducts = payload.offlineProducts;
      if (!isEmpty(state?.activeLocationId)) {
        const idx = payload.hubList.findIndex((hub) => hub?.locationId === state.activeLocationId);
        if (idx !== -1) {
          state.activeHub = state.hubList[idx];
        }
      }
    },
    setDeletedDevicesAndHubList(state, { payload }: PayloadAction<SetDeletedDevicesAndHubList>) {
      state.deletedDevicesAndHubList = payload;
    },

    updateStatus(state, { payload }: PayloadAction<{ [key: string]: number }>) {
      if (Object.keys(payload).length === 0) return;

      state.devicesList = state.devicesList.map((device) => updateDeviceStatus(device, payload));
      state.hubList = state.hubList.map((hub) => updateHubStatus(hub, payload));
      state.activeHub = updateHubStatus(state.activeHub, payload);
    },

    setActiveHub(state, { payload }: PayloadAction<string>) {
      const idx = state.hubList.findIndex((hub) => hub?.id === payload);
      if (idx === -1) {
        state.activeHub = EMPTY_ACTIVE_HUB;
      } else {
        state.activeHub = state.hubList[idx];
      }
    },
    setActiveLocationId(state, { payload }: PayloadAction<string>) {
      state.activeLocationId = payload;

      // localStorage gets cleared on logout so...
      // we use a cookie to remember the location across sessions.
      setCookie('activeLocation', payload, 30);

      const idx = state.hubList.findIndex((hub) => hub?.locationId === payload);
      if (idx === -1) {
        state.activeHub = EMPTY_ACTIVE_HUB;
      }
      state.activeHub = state.hubList[idx];
    },

    setOcppSettings(state, { payload }: PayloadAction<OcppSettings | null>) {
      state.ocppSettings = payload;
    },
    setOcppTransactionStatus(
      state,
      { payload }: PayloadAction<OcppTransactionStatus | undefined | null>
    ) {
      state.ocppTransactionStatus = payload;
    },
    setOcppTransactionStatusError(state, { payload }: PayloadAction<boolean>) {
      state.ocppTransactionStatusError = payload;
    }
  }
});

export const {
  setLastGeneratedApiKey,
  setDevicesAndHubList,
  setDeletedDevicesAndHubList,
  setActiveHub,
  setActiveLocationId,
  setOcppSettings,
  setOcppTransactionStatus,
  setOcppTransactionStatusError,
  updateStatus
} = productsSlice.actions;
export const products = productsSlice.reducer;
