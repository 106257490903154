/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Manufacturer, UserVehicle, VehicleData, Vehicles } from 'types/vehicles';

const initialState: Vehicles = {
  userVehicles: [],
  manufacturers: [],
  allVehicleData: {}
};

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    setUserVehicles(state, { payload }: PayloadAction<UserVehicle[]>) {
      state.userVehicles = payload;
    },
    setVehicleManufacturers(state, { payload }: PayloadAction<Manufacturer[]>) {
      state.manufacturers = payload;
    },
    setAllVehicleData(state, { payload }: PayloadAction<VehicleData>) {
      state.allVehicleData = payload;
    },
    addEditUserVehicle(state, { payload }: PayloadAction<UserVehicle>) {
      const idx = state?.userVehicles?.findIndex((userVehicle) => userVehicle?.id === payload?.id);
      if (idx !== -1) {
        state.userVehicles[idx] = payload;
      } else {
        state.userVehicles.push(payload);
      }
    }
  }
});

export const { setUserVehicles, setVehicleManufacturers, setAllVehicleData, addEditUserVehicle } =
  vehiclesSlice.actions;
export const vehicles = vehiclesSlice.reducer;
