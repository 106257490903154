/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { loadText } from 'api/requests';
import { useAppSelector } from 'customHooks';
import { useEffect, useState } from 'react';

const Loading = () => {
  const store = useAppSelector((state) => state);
  const [data, setData] = useState<{ loading: string }>({ loading: '' });

  const { language } = store.userDetails.personalInfo.preferences;

  useEffect(() => {
    loadText('loading', language).then((res) => setData(res?.data));
  }, [language]);

  return (
    <div className="page-loading" data-testid="loader">
      <i className="loader"></i>
      <span>{data.loading}</span>
    </div>
  );
};

export default Loading;
