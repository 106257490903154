/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { Months } from 'constants/enums';

import { IMonthData } from './datepicker.types';

export const getMonthFrameList = (data: IMonthData) => {
  return [
    { key: 0, value: Months.JANUARY, label: data?.january },
    { key: 1, value: Months.FEBRUARY, label: data?.february },
    { key: 2, value: Months.MARCH, label: data?.march },
    { key: 3, value: Months.APRIL, label: data?.april },
    { key: 4, value: Months.MAY, label: data?.may },
    { key: 5, value: Months.JUNE, label: data?.june },
    { key: 6, value: Months.JULY, label: data?.july },
    { key: 7, value: Months.AUGUST, label: data?.august },
    { key: 8, value: Months.SEPTEMBER, label: data?.september },
    { key: 9, value: Months.OCTOBER, label: data?.october },
    { key: 10, value: Months.NOVEMBER, label: data?.november },
    { key: 11, value: Months.DECEMBER, label: data?.december }
  ];
};
