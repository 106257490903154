/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { DeviceType, ProductCodes } from 'constants/DeviceType';
import { isEmpty } from 'lodash-es';
import { Device } from 'types';

import { processProductCode } from './products';

/**
 * Get all devices available for access management from the active location
 * @param locationId: Active location id - taken from store.location.activeLocation.energySetup
 * @param devices: All devices list - taken from store.products.devicesList
 * @returns all devices available for access management from the active location
 */
export const getAMAvailableDevices = (locationId: string, devices: Device[]): Device[] => {
  if (isEmpty(devices)) {
    return [];
  }

  const ZAPPI2TI = ProductCodes.Zappi2Ti;
  const ZappiGLO = ProductCodes.ZappiGLO;
  const MINIMUM_FIRMWARE_VERSION = 5.105;

  return devices.filter((device) => {
    const { locationId: deviceLocationId, firmware } = device;

    if (deviceLocationId !== locationId) {
      return false;
    }

    const [deviceVersion, firmwareVersion] = processProductCode(firmware) || [];

    const isZappi2Ti = deviceVersion === ZAPPI2TI || deviceVersion === ZappiGLO;

    const meetsFirmwareRequirement = firmwareVersion
      ? firmwareVersion > MINIMUM_FIRMWARE_VERSION
      : false;

    return device.deviceType === DeviceType.Zappi && isZappi2Ti && meetsFirmwareRequirement;
  });
};

export const deviceStatusesInitialValues = {
  none: '',
  active: '',
  new: '',
  inactive: '',
  accepted: '',
  pending: '',
  deleted: ''
};
