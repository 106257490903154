/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { ADDRESS_FIELDS } from './constants';

export interface LocationData {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface ILookup {
  label?: string;
  onAddressSelect: (addressFields: { [x: string]: string }, addressWithPostCode: string) => void;
  loadingText: string;
  notCompleteError: string;
}

export interface IAddressData {
  addressLine1: string;
  postalCode: string;
  city: string;
  region: string;
  country?: string;
}

export const validateTypes = (el: LocationData, types: string[]) =>
  types.some((type) => el.types.includes(type));

export const getAddressField = (data: LocationData[], types: string[]) => {
  const addressComponent = data.find((el) => validateTypes(el, types));

  return addressComponent ? addressComponent.long_name : '';
};

export const getCountryCode = (data: LocationData[]) => {
  const addressComponent = data.find((el) => validateTypes(el, ['country']));

  return (addressComponent && addressComponent.short_name) || '';
};

export const buildAddressLine = (address_components: LocationData[], formatted_address: string) => {
  const components = [];
  const route = getAddressField(address_components, ['route']);
  const streetNumber = getAddressField(address_components, ['street_number']);
  const streetAddress = getAddressField(address_components, ['street_address']);
  const formattedStreetAddress = formatted_address.split(',')[0];

  if (streetAddress) {
    return streetAddress;
  }

  if (
    (streetAddress && formattedStreetAddress.includes(streetAddress)) ||
    (route &&
      formattedStreetAddress.includes(streetNumber) &&
      formattedStreetAddress.includes(route))
  ) {
    return formattedStreetAddress;
  }

  if (streetNumber) {
    components.push(streetNumber);
  }

  if (route) {
    components.push(route);
  }

  return components.join(' ');
};

export const buildAddressFields = (geocode: google.maps.GeocoderResult) => {
  const { address_components = [], formatted_address = '' } = geocode;

  return {
    [ADDRESS_FIELDS.line1]: buildAddressLine(address_components, formatted_address),
    [ADDRESS_FIELDS.city]: getAddressField(address_components, ['postal_town', 'locality']),
    [ADDRESS_FIELDS.region]: getAddressField(address_components, [
      'administrative_area_level_2',
      'administrative_area_level_1'
    ]),
    [ADDRESS_FIELDS.postalCode]: getAddressField(address_components, ['postal_code']),
    [ADDRESS_FIELDS.country]: getAddressField(address_components, ['country']),
    [ADDRESS_FIELDS.countryCode]: getCountryCode(address_components)
  };
};

//fix address values type from unknown to string
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAddress(obj: any): obj is IAddressData {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'addressLine1' in obj &&
    'postalCode' in obj &&
    'city' in obj &&
    'region' in obj &&
    'country' in obj
  );
}
