/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/

/* eslint-disable @myenergi-ltd/prefer-utc-date */
import { loadText } from 'api/requests';
import { route } from 'containers/appRouter';
import { useAppSelector } from 'customHooks';
import { memo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  generateLanguageLink
} from 'utils/termsLink';

interface IFooterData {
  copyrightText?: string;
  privacyText?: string;
  termsText?: string;
}

const FooterLinks = () => {
  const [data, setData] = useState<IFooterData>({});
  const { language } = useAppSelector((state) => state.userDetails.personalInfo.preferences);

  useEffect(() => {
    loadText('footer', language).then((res) => setData(res?.data));
  }, [language]);

  return (
    <div className="footer__group_links">
      <a
        className="footer__links"
        href={generateLanguageLink(language ?? '', PRIVACY_POLICY_URL, ['nl-NL'], 'nl/fr/')}
        rel="noreferrer"
        target="_blank"
      >
        {data.privacyText}
      </a>
      <a
        className="footer__links"
        href={generateLanguageLink(language, TERMS_AND_CONDITIONS_URL)}
        rel="noreferrer"
        target="_blank"
      >
        {data.termsText}
      </a>
    </div>
  );
};

const RenderBasicFooter = () => {
  return (
    <footer className="footer" data-testid="basic-footer">
      <FooterLinks />
    </footer>
  );
};

const RenderLoginFooter = () => {
  const [data, setData] = useState<IFooterData>({});
  const { language } = useAppSelector((state) => state.userDetails.personalInfo.preferences);
  const today = new Date();

  useEffect(() => {
    loadText('footer', language).then((res) => setData(res?.data));
  }, [language]);

  return (
    <footer className="footer login" data-testid="login-footer">
      <span>{`${data.copyrightText}-${today.getFullYear()}`}</span>
      <FooterLinks />
    </footer>
  );
};

const MPFooter = () => {
  const pathname = useLocation().pathname;

  switch (true) {
    case pathname === route.LOGIN ||
      pathname === route.REGISTRATION ||
      pathname === route.SOCIAL_REGISTRATION ||
      pathname === route.FORGOT_PASSWORD ||
      pathname === route.VERIFICATION:
      return <RenderLoginFooter />;
    default:
      return <RenderBasicFooter />;
  }
};

const Footer = memo(MPFooter);
export default Footer;
