/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCookie } from 'components/platform/cookiebar/helper';
import { DATE_FORMATS, DISTANCE_UNITS, LANGUAGES, CURRENCY } from 'shared/js/sharedEnums';
import { BillingDetails, Marketing, PersonalInfo, Preference, User } from 'types';

const initialState: User = {
  personalInfo: {
    accountType: 0,
    email: '',
    creationDate: '',
    marketing: {
      marketingOptIn: false,
      thirdPartyMarketingOptIn: false
    },
    preferences: {
      distance: DISTANCE_UNITS.MILES,
      dateFormat: DATE_FORMATS.DD_MM_YYYY,
      language: getCookie('preferedLanguage') || LANGUAGES.EN_GB,
      currency: CURRENCY.GBP,
      country: ''
    },
    displayedTiles: {
      displayAccountSharingTile: true,
      displayElectricVehicleTile: true,
      displayEwTile: true,
      displayUserJourneyBoard: true
    },
    userFlags: {
      isBetaTester: false,
      isSelectedForDSR: false,
      isOptedIn: false,
      superUserFlags: {
        isSuperUserAvailable: false,
        isSuperUserLinkedToTheClient: false
      }
    },
    title: '',
    firstName: '',
    lastName: '',

    birthDate: '',
    contactAddress: {
      id: '',
      siteName: null,
      addressLine1: '',
      addressLine2: null,
      city: '',
      region: '',
      postalCode: '',
      country: '',
      countryCode: '',
      isContactAddress: false,
      isEWEligible: false,
      isGuestAddress: false,
      currency: '',
      shouldValidateAddress: false
    },
    mobileNumber: '',
    mobileCode: '',
    landlineNumber: '',
    landlineCode: ''
  },
  billingDetails: undefined,
  password: '',
  newEmail: '',
  sub: '',
  invitationId: '',
  authState: null,
  regStep: '',
  isGuest: false,
  isFirstTimeReg: false,
  isUpdatingEmail: false
};

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setPersonalInfo(state, action: PayloadAction<PersonalInfo>) {
      state.personalInfo = { ...state.personalInfo, ...action.payload };
    },
    setMarketingPref(state, action: PayloadAction<Marketing>) {
      state.personalInfo.marketing = { ...state.personalInfo.marketing, ...action.payload };
    },
    setPreferences(state, action: PayloadAction<Preference>) {
      state.personalInfo.preferences = { ...state.personalInfo.preferences, ...action.payload };
    },
    setBillingDetails(state, action: PayloadAction<BillingDetails>) {
      state.billingDetails = { ...state.billingDetails, ...action.payload };
    },
    setAccountType(state, action: PayloadAction<number>) {
      state.personalInfo.accountType = action.payload;
    },
    setUserEmail(state, action: PayloadAction<string>) {
      state.personalInfo.email = action.payload;
    },
    setUserPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setUserSub(state, action: PayloadAction<string>) {
      state.sub = action.payload;
    },
    setNewUserEmail(state, action: PayloadAction<string>) {
      state.newEmail = action.payload;
    },
    setAuthState(state, action: PayloadAction<string | null>) {
      state.authState = action.payload;
    },
    setIsFirstTimeReg(state, action: PayloadAction<boolean>) {
      state.isFirstTimeReg = action.payload;
    },
    setRegStep(state, action: PayloadAction<string>) {
      state.regStep = action.payload;
    },
    setIsUpdatingEmail(state, action: PayloadAction<boolean>) {
      state.isUpdatingEmail = action.payload;
    }
  }
});

export const {
  setPersonalInfo,
  setMarketingPref,
  setPreferences,
  setBillingDetails,
  setAccountType,
  setUserEmail,
  setUserPassword,
  setUserSub,
  setNewUserEmail,
  setAuthState,
  setRegStep,
  setIsFirstTimeReg,
  setIsUpdatingEmail
} = userDetailsSlice.actions;

export const userDetails = userDetailsSlice.reducer;
