/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
export interface IMPCookieBarData {
  title?: string;
  desc1?: string;
  desc2?: string;
  privacyPolicy?: string;
  acceptButton?: string;
  declineButton?: string;
  necessaryTitle?: string;
  necessaryDesc?: string;
  analyticalTitle?: string;
  analyticalDesc?: string;
  toggleOn?: string;
  toggleOff?: string;
}

export interface ICookieBarFormProps {
  data: IMPCookieBarData;
  language: string;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

// helper functions

export const setCookie = (cName: string, cValue: boolean | string, expDays: number) => {
  if (!cName) return;
  if (!cValue) return;

  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);

  const expires = 'expires=' + date.toUTCString();
  const string = `${cName}=${cValue};${expires};path=/`;
  document.cookie = string;
  return;
};

export const getCookie = (cName: string) => {
  if (!cName) return;

  const name = cName + '=';
  const cDecoded = decodeURIComponent(document.cookie);
  const cArr = cDecoded.split('; ');

  let res;

  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });

  return res;
};

export const deleteCookie = (cName: string) => {
  const date = new Date();
  date.setTime(date.getTime() - 365 * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  const string = `${cName}=;${expires};path=/`;
  document.cookie = string;
  return;
};
