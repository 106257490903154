/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { loadText } from 'api/requests';
import CountrySelect from 'components/countrySelect';
import Button from 'components/elements/button';
import { BUTTON_COLORS, BUTTON_TYPE } from 'components/elements/button/consts';
import SelectOption from 'components/elements/select';
import { setPreferences } from 'containers/appStore';
import { useAppSelector, useAppDispatch } from 'customHooks';
import { Formik, Form, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash-es';
import { FC, ReactNode, useEffect, useState, Dispatch, SetStateAction } from 'react';

import { getCodeFromLanguage, getLanguageFromCode } from './helpers';

interface ISitePreferences {
  children?: ReactNode;
  active: boolean;
  handleShowPopup: () => void;
  setActive: Dispatch<SetStateAction<boolean>>;
}
interface FormValues {
  country: string;
  language: string;
}

const SitePreferences: FC<ISitePreferences> = ({
  children,
  active,
  handleShowPopup,
  setActive
}) => {
  const store = useAppSelector((store) => store);
  const dispatch = useAppDispatch();
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const [data, setData] = useState({
    title: '',
    selectCountry: '',
    selectLanguage: '',
    countries: {
      de: '',
      fr: '',
      nl: '',
      be: '',
      lu: '',
      australia: '',
      nz: '',
      gb: ''
    },
    languages: {
      en: '',
      de: '',
      fr: '',
      nl: ''
    },
    cancelBtnText: '',
    submitBtnText: ''
  });

  const { language, country } = store.userDetails.personalInfo.preferences;
  const { isAllDataReady } = store.site;

  const getInitialValues = () => {
    return {
      country: country || 'gb',
      language: getLanguageFromCode[language]
    };
  };

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    document.cookie = `preferedLanguage=${getCodeFromLanguage[values.language]}`;
    document.cookie = `preferedCountry=${values.country}`;

    const objPreferences = {
      ...store.userDetails.personalInfo.preferences,
      language: getCodeFromLanguage[values.language],
      country: values.country
    };

    dispatch(setPreferences(objPreferences));

    setActive(false);
    setSubmitting(false);
  };
  const handleCancel = () => {
    handleShowPopup();
  };

  const getObjectList = (data: { [key: string]: string }) => {
    const countriesArray =
      data &&
      Object?.entries(data).map(([value, label]) => ({
        value,
        label
      }));
    return countriesArray;
  };

  useEffect(() => {
    loadText('sitepreferences', language).then((res) => {
      setData(res?.data);
    });
  }, [language]);

  if (isEmpty(children) || !isAllDataReady) {
    return <></>;
  }

  return (
    <div className="site_preferences__wrapper" data-testid="site-preferences">
      <div data-testid="children">{children}</div>
      <div className={`site_preferences__box bottom ${active ? 'active' : ''}`}>
        <div className="site_preferences">
          <div className="site_preferences__content">
            <div className="site_preferences__content__title">{data?.title}</div>
            <Formik initialValues={getInitialValues()} onSubmit={handleSubmit}>
              {({ isSubmitting }) => {
                return (
                  <Form className="site_preferences__content__form">
                    <CountrySelect
                      name="country"
                      optionList={getObjectList(data?.countries)}
                      label={data.selectCountry}
                    />
                    <label
                      className={`site_preferences__content__form__label__${isMenuOpened ? 'opened' : 'closed'}`}
                    >
                      {data.selectLanguage}
                    </label>
                    <div className="site_preferences__content__form__language">
                      <SelectOption
                        name="language"
                        id="language"
                        optionList={getObjectList(data?.languages)}
                        isSearchable={false}
                        isOverlapping={false}
                        setIsMenuOpened={setIsMenuOpened}
                      />
                    </div>
                    <div className="site_preferences__buttons">
                      <Button
                        className={BUTTON_COLORS.green}
                        label={data.cancelBtnText}
                        onClick={handleCancel}
                        isDisabled={isSubmitting}
                        data-testid="site-preferences-cancel"
                      />
                      <Button
                        className={`${BUTTON_COLORS.green} ${BUTTON_COLORS.filled}`}
                        type={BUTTON_TYPE.SUBMIT}
                        isDisabled={isSubmitting}
                        label={data.submitBtnText}
                        testId={'personal-info-submit'}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SitePreferences;
