/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import FormError from 'components/elements/formError';
import { Field } from 'formik';
import { FieldInputProps, FieldMetaProps } from 'formik/dist/types';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { tracking } from 'shared/js';

interface CheckboxProps {
  onChange?: (arg: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  name: string;
  value?: boolean;
  label?: string;
  color?: string;
  description?: string;
  disabled?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({
  onChange,
  id,
  name,
  value,
  label,
  color,
  description,
  disabled
}) => {
  const location = useLocation();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: FieldInputProps<never>
  ) => {
    const { onChange: formikOnChange } = field;

    formikOnChange(event);

    if (typeof onChange === 'function') {
      onChange(event);
    }

    tracking({
      name: `${name} ckeckbox`,
      location: `${location.pathname}/ ${location.hash.replace('#', '')}`,
      action: 'click'
    });

    return;
  };

  const onChangeValue = (field: FieldInputProps<never>) => {
    return onChange ? value : field.value;
  };

  return (
    <Field name={name}>
      {({ field, meta }: { field: FieldInputProps<never>; meta: FieldMetaProps<never> }) => (
        <div
          className={`form-checkbox ${color || ''} ${onChangeValue(field) ? 'checked' : ''}`}
          data-testid="field-checkbox"
        >
          <input
            id={id}
            type="checkbox"
            data-testid="field-checkbox-input"
            {...field}
            onChange={(e) => handleChange(e, field)}
            checked={onChangeValue(field)}
            disabled={disabled}
          />
          <label htmlFor={id} className={`${description ? 'has-description' : ''}`}>
            <span>{label}</span>
            {description && <span>{description}</span>}
          </label>

          {meta.touched && meta.error && <FormError meta={meta} />}
        </div>
      )}
    </Field>
  );
};

export default Checkbox;
