/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { setError } from 'containers/appStore';
import { useAppDispatch } from 'customHooks';
import { useState, useEffect } from 'react';
import { AnyAction } from 'redux';

/* useGetData
 ** @apiRequest -> request to database
 ** @apiProcessor -> processor to store
 ** @setStore -> store reducer
 ** @apiParams -> params to be sent
 */

export const useGetData = (
  apiRequest: (data: any, params?: any) => Promise<any>,
  apiProcesor: ((data: any) => any) | undefined,
  setStore: (value: any) => AnyAction,
  errorMessage: string,
  apiParams?: any
) => {
  const dispatch = useAppDispatch();
  const [isDone, setIsDone] = useState<boolean>(false);

  useEffect(() => {
    if (!isDone) {
      apiRequest(apiParams !== undefined ? apiParams : null).then((res) => {
        if (!res.status || res.statusCode > 300) {
          console.error(res);
          dispatch(setError(res.message || errorMessage));
          return;
        }

        const processInfo =
          apiProcesor && typeof apiProcesor === 'function'
            ? apiProcesor(res?.content)
            : res.content;
        dispatch(setStore(processInfo));
        setIsDone(true);
      });
    }

    // eslint-disable-next-line
  }, []);

  return isDone;
};
