/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Icon from 'components/elements/icon';
import { toggleMobileDrawer } from 'containers/appStore';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

interface ExpandableMenuButtonProps {
  route: string;
  onClick: (arg: string) => void;
  isDisabled?: boolean;
  className?: string;
  type?: string;
  icon: string;
  title?: string;
  tabs: {
    hash: string;
    label: string;
  }[];
  active: boolean;
  hash: string | undefined;
  testId?: string;
  testIdLink?: string;
}

const ExpandableMenuButton: FC<ExpandableMenuButtonProps> = ({
  route,
  onClick,
  isDisabled,
  className,
  type,
  icon,
  title,
  tabs,
  active,
  hash,
  testIdLink = 'expandablemenubutton-button-link',
  testId
}) => {
  const dispatch = useDispatch();

  const handleClickSubmenu = () => {
    dispatch(toggleMobileDrawer(false));
  };

  return (
    <div
      className={`expandablemenu ${active ? 'active' : ''} `}
      data-testid="expandable-menu-button"
    >
      <button
        className={`menubutton ${className || ''} ${type || 'col'} ${isDisabled ? 'disabled' : ''} ${active ? 'active' : ''}`}
        type="button"
        onClick={() => onClick(route)}
        data-testid={testId}
      >
        <Icon name={active ? `${icon}-active` : `${icon}-active`} />
        <span className="menubutton__title">{title || ''}</span>
      </button>

      <ul className={`submenubuttons`}>
        {active &&
          tabs.map((tab) => (
            <Link
              key={tab.label}
              to={`${route}${tab.hash}`}
              className={`submenubutton ${tab.hash === hash ? 'active' : ''}`}
              onClick={handleClickSubmenu}
              data-testid={testIdLink}
            >
              <li className="submenubutton__title">{tab.label}</li>
            </Link>
          ))}
      </ul>
    </div>
  );
};

export default ExpandableMenuButton;
