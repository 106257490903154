/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { DeviceType } from 'constants/DeviceType';
import { Period } from 'constants/enums';
import { BatteryUsage, EnergyUsage } from 'constants/graphData';
import { isEmpty } from 'lodash-es';
import { getTime } from 'shared/js';
import dateCreator from 'utils/dateCreator';
import dayjs from 'utils/dayjs';

import { GPAPH_STACKING, USAGE_SETS } from './const';
import { getStartingTimestampByLabel } from './helpers';

export const filterDataSets = (dataSets, filters, type) => {
  if (!dataSets || !filters) return dataSets;
  // filter data sets to selected ones only
  return type === USAGE_SETS
    ? dataSets.filter((set) => filters[set.id]?.active)
    : dataSets.filter((set) => filters[set.name.toLowerCase()]?.active);
};

const computeUsageValue = (set) => {
  return set.data.reduce((sum, el) => {
    return sum + el[1] * 1000000;
  }, 0);
};

// for testing only, it seems we'll use minute data so this flag won't be needed
const mapPower = (power, isMinData) =>
  isMinData ? Math.abs((power / 60) * Math.max(5, 1)) : Math.abs(power);

const calculateUsageValue = (set, isMinData) => {
  return set.data.reduce((sum, el) => sum + mapPower(el[1], isMinData), 0);
};

export const calculateTotals = (dataSets = [], period, startDate, endDate) => {
  const isMinData =
    period === Period.TODAY ||
    period === Period.YESTERDAY ||
    (period === Period.CUSTOM && startDate.isSame(endDate, 'day'));

  return dataSets?.reduce((acc, set) => {
    if (!set.name) {
      return acc;
    }

    let total = Math.max(acc[set.name] || 0, calculateUsageValue(set, isMinData));
    const totals = {
      total,
      unit: set.name === BatteryUsage.SOC ? `${set.unit} now` : set.unit,
      name: set.name,
      id: set.id,
      firmware: set?.firmware,
      label: set.label,
      type: set.devicetype,
      color: set.color,
      active: true
    };

    return [...acc, totals];
  }, []);
};

// convert point properties {x, y} into a duple [x, y]
const convertPoints = (points) => {
  if (!points) return [];

  return points.reduce((ac, point) => {
    const utcDate = point.x.tz('Etc/UTC', true);

    // convert x dayjs to timestamp
    ac.push([utcDate.valueOf(), point.y]);

    return ac;
  }, []);
};

export const mapGraphData = (content, devicesList = [], type, data) => {
  if (!content) return [];

  return content?.map((set) => {
    let { name, label, dataPoints, id, color, serialNumber } = set;

    // try to get device type from devices list to use with processed data list
    const [device] = devicesList.filter((device) => {
      return device?.serialNumber === id.substring(1);
    });
    const firmware = device?.firmware;

    let fillOpacity = type === 'usage' ? 0.5 : 1;
    let lineWidth = 0;
    let unit = 'kWh';

    if (type === 'battery') {
      fillOpacity = id === BatteryUsage.SOC ? 0 : 0.3;
      lineWidth = id === BatteryUsage.SOC ? 2 : 1;
      unit = id === BatteryUsage.SOC ? '%' : unit;
    }

    if (id === EnergyUsage.HOME) {
      label = data?.home;
    }

    name = name?.toLowerCase() || id?.toLowerCase();
    return {
      name,
      label: label || name,
      serialNumber,
      firmware,
      id,
      devicetype: device?.deviceType || id,
      data: convertPoints(dataPoints),
      color,
      fillOpacity,
      lineWidth,
      unit,
      // we're hidding SOC from navigator as it can't cope with different yaxis scales for SOC and charge/discharge
      showInNavigator: id !== BatteryUsage.SOC,
      yAxis: id === BatteryUsage.SOC ? 1 : 0,
      zIndex: id === EnergyUsage.HOME ? -1 : undefined,
      dashStyle: id === BatteryUsage.SOC ? 'ShortDot' : 'Solid',
      stacking: GPAPH_STACKING[device?.deviceType || id.toLowerCase()],
      lastUpdate: Number(new Date().getTime()),
      animation: false,
      dataGrouping: {
        enabled: false
      },
      states: {
        hover: {
          enabled: false
        }
      }
    };
  });
};

export const getFilterFromData = (content, sets) => {
  if (!content) return [];
  return content.reduce((ac, cv) => {
    if (sets === USAGE_SETS) {
      ac[cv.id] = { name: cv.id, active: true };
    } else {
      ac[cv.name] = { name: cv.name, active: true };
    }
    return ac;
  }, {});
};

export const getUTCTimeStamp = (startDate) => {
  const utcDate = dateCreator
    ?.create(startDate)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .utc();
  return utcDate.valueOf();
};

// This is the sort logic from the mobile app.
// The devices order is important so the calculations are the same as the app.
export const filterAndSortDevices = (devices) => {
  return devices
    .filter(
      (device) => device.deviceType === DeviceType.Eddi || device.deviceType === DeviceType.Zappi
    )
    .sort((device1, device2) => {
      if (device1.deviceType === device2.deviceType) return 0;
      if (device1.deviceType === DeviceType.Eddi) return -1;
      return 1;
    });
};

// checks if given period is partially in the now
export const isPresentPeriod = (period) => {
  return period === Period.TODAY || period === Period.THIS_WEEK || period === Period.THIS_MONTH;
};
