/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { loadText } from 'api/requests';
import Icon from 'components/elements/icon';
import LocationSwitch from 'components/locationSwitch';
import SitePreferences from 'components/sitePreferences';
import { route } from 'containers/appRouter';
import { signOut } from 'containers/authController';
import { useAppSelector } from 'customHooks';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { generateLanguageLink } from 'utils/termsLink';

const HeaderMenu = () => {
  const [active, setActive] = useState(false);
  const [data, setData] = useState<{ forum: string; logout: string }>({ forum: '', logout: '' });
  const history = useHistory();
  const pathname = useLocation().pathname;

  const store = useAppSelector((store) => store);
  const { language } = store.userDetails.personalInfo.preferences;

  const isRegistration = pathname === route.VERIFICATION || pathname === route.SOCIAL_REGISTRATION;
  const isReset = pathname === route.FORGOT_PASSWORD;

  const handleShowPopup = () => {
    if (!active) {
      setActive(true);
    } else setActive(false);
  };

  useEffect(() => {
    loadText('headermenu', language).then((res) => setData(res?.data));
  }, [language]);

  return (
    <div
      className={`header-menu ${isRegistration ? 'registration' : ''}`}
      data-testid={'header-menu'}
    >
      <div className={`header-menu__links`}>
        {isRegistration && (
          <button
            type="button"
            className="link border logout"
            onClick={() => {
              signOut();
              localStorage.clear();
              history.push('/logout');
            }}
            data-testid="button-header-menu"
          >
            {data?.logout}
          </button>
        )}
        <a
          href={generateLanguageLink(language)}
          className={`${isRegistration ? 'border' : ''}`}
          target="_blank"
          rel="noreferrer"
        >
          myenergi.com
        </a>
        <a href="https://myenergi.info/" target="_blank" rel="noreferrer">
          {data?.forum}
        </a>
      </div>

      <div className="header-menu__language border">
        <SitePreferences active={active} handleShowPopup={handleShowPopup} setActive={setActive}>
          <Icon
            name={`${active ? 'language' : 'language-grey'}`}
            onClick={handleShowPopup}
            testId={'header-menu-icon'}
            size={16}
          />
        </SitePreferences>
      </div>

      {!isRegistration && !isReset && <LocationSwitch />}
    </div>
  );
};

export default HeaderMenu;
