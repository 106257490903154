/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
interface yearListType {
  label: number;
  value: number;
}

export const buildYears = (start?: string | number, end?: string | number) => {
  const yearList: yearListType[] = [];

  if (!start) return yearList;
  if (!end) return [{ label: Number(start), value: Number(start) }];

  for (let i = Number(start); i <= Number(end); i++) {
    yearList.push({ label: i, value: i });
  }

  return yearList;
};

export const buildYearsReversed = (start?: string | number, end?: string | number) => {
  const yearList: { label: string; value: string }[] = [];

  if (!start) return yearList;
  if (!end) return [{ label: start.toString(), value: start.toString() }];

  for (let i = Number(start); i >= Number(end); i--) {
    yearList.push({ label: i.toString(), value: i.toString() });
  }

  return yearList;
};

export const buildOtherYearList = () => {
  const date = new Date().getUTCFullYear();
  return new Array(10)
    .fill('date')
    .map((year, index) => ({ label: date - index, value: date - index }));
};
