/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
export interface IData {
  locationMissingTitle: string;
  locationSwitchPopUpTitle: string;
  locationSwitchPopUpDescription: string;
  locationSwitchPopUpAddLabel: string;
  locationSwitchPopUpAcceptLabel: string;
  locationSwitchPopUpIgnoreLabel: string;
  locationSwitchPopUpSharedByLabel: string;
  locationPopUpTitle: string;
  locationPopUpDescription: string;
  error: string;
}

export interface ILocationSwitchModal {
  onClose: () => void;
  onAddClick: () => void;
  sharedByLabel: string;
  addLabel: string;
  acceptLabel: string;
  ignoreLabel: string;
  testId?: string;
  error: string;
}

export const locationSwitchDataInitialValues = {
  locationMissingTitle: '',
  locationSwitchPopUpTitle: '',
  locationSwitchPopUpDescription: '',
  locationSwitchPopUpAddLabel: '',
  locationSwitchPopUpAcceptLabel: '',
  locationSwitchPopUpIgnoreLabel: '',
  locationSwitchPopUpSharedByLabel: '',
  locationPopUpTitle: '',
  locationPopUpDescription: '',
  error: ''
};
