/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
interface AddressFieldProp {
  manual: string;
  fullAddress: string;
  line1: string;
  line2: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
  countryCode: string;
  address: string;
  id: string;
}

export const ADDRESS_FIELDS: AddressFieldProp = {
  manual: 'manual',
  fullAddress: 'fullAddress',
  line1: 'addressLine1',
  line2: 'addressLine2',
  city: 'city',
  region: 'region',
  postalCode: 'postalCode',
  country: 'country',
  countryCode: 'countryCode',
  address: 'address',
  id: 'id'
};
