/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Button from 'components/elements/button';
import { BUTTON_COLORS, BUTTON_TYPE } from 'components/elements/button/consts';
import { Dispatch, FC, SetStateAction } from 'react';
import { LIBBI_TARIFF_SETUP_WARNING, tracking } from 'shared/js';
import { ILocationFormValues } from 'types';
import { WARRANTY_TERMS_AND_CONDITIONS_URL, generateLanguageLink } from 'utils/termsLink';

interface ExtendedWarrantyWarningProps {
  values: ILocationFormValues;
  handleSubmit: (values: ILocationFormValues) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  onClose?: () => void;
  data: {
    description: string;
    link: string;
    cancelBtn: string;
    confirmBtn: string;
  };
  language: string;
  bothPopups: boolean;
  setPopupAlert: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
}

const ExtendedWarrantyWarning: FC<ExtendedWarrantyWarningProps> = ({
  data,
  language,
  onClose,
  handleSubmit,
  values,
  setSubmitting,
  bothPopups,
  setPopupAlert
}) => {
  const handleSave = () => {
    bothPopups ? setPopupAlert({ [LIBBI_TARIFF_SETUP_WARNING]: true }) : handleSubmit(values);

    tracking({
      name: 'Confirm button',
      location: 'Location / Edit Location / Extended Warranty Warning',
      action: 'click'
    });
  };

  const handleCancel = () => {
    onClose && onClose();
    setSubmitting(false);

    tracking({
      name: 'Cancel button',
      location: 'Location / Edit Location / Extended Warranty Warning',
      action: 'click'
    });
  };

  return (
    <div className="extended-warranty-warning" data-testid="extended-warranty-warning">
      <div className="extended-warranty-warning__description">
        <span>{data.description}</span>
        <a
          href={generateLanguageLink(
            language,
            WARRANTY_TERMS_AND_CONDITIONS_URL,
            ['fr-FR'],
            'nl-NL'
          )}
          target="_blank"
          rel="noreferrer"
        >
          {data.link}
        </a>
        .
      </div>
      <div className="extended-warranty-warning__buttons">
        <Button label={data.cancelBtn} className={BUTTON_COLORS.green} onClick={handleCancel} />
        <Button
          label={data.confirmBtn}
          type={BUTTON_TYPE.SUBMIT}
          className={`${BUTTON_COLORS.green} ${BUTTON_COLORS.filled}`}
          onClick={handleSave}
          data-testid="EW-Warning-submit"
        />
      </div>
    </div>
  );
};

export default ExtendedWarrantyWarning;
