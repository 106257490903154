/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CsvReportsDevices,
  CsvReportsSelectedDevice,
  CsvReportsState
} from 'components/csvReports/csvReports.types';
import { Dayjs } from 'dayjs';
import dayjs from 'utils/dayjs';

const initialState: CsvReportsState = {
  displayedDevices: [],
  selectedDevices: [],
  selectedHub: '',
  destinationEmail: '',
  period: {
    startDate: dayjs(),
    endDate: dayjs()
  }
};

const csvReportsSlice = createSlice({
  name: 'csvReports',
  initialState,
  reducers: {
    setCsvReportsDisplayedDevices(state, { payload }: PayloadAction<CsvReportsDevices[]>) {
      state.displayedDevices = payload;
    },

    setCsvReportsSelectedDevices(state, { payload }: PayloadAction<CsvReportsSelectedDevice[]>) {
      state.selectedDevices = payload;
    },

    setCsvReportsSelectedHub(state, { payload }: PayloadAction<string>) {
      state.selectedHub = payload;
    },

    setCsvReportsPeriod(state, { payload }: PayloadAction<{ startDate: Dayjs; endDate: Dayjs }>) {
      state.period = payload;
    },

    setCsvReportsDestinationEmail(state, { payload }: PayloadAction<string>) {
      state.destinationEmail = payload;
    },

    resetCsvReportsState(state, { payload }: PayloadAction<Partial<CsvReportsState>>) {
      return { ...initialState, ...payload };
    }
  }
});

export const {
  setCsvReportsSelectedDevices,
  setCsvReportsDisplayedDevices,
  setCsvReportsSelectedHub,
  setCsvReportsPeriod,
  setCsvReportsDestinationEmail,
  resetCsvReportsState
} = csvReportsSlice.actions;

export const csvReports = csvReportsSlice.reducer;
