/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Icon from 'components/elements/icon';
import React, { FC, useState } from 'react';

import { IconButtonProps } from './iconButton.types';

const IconButton: FC<IconButtonProps> = ({
  label,
  icon,
  size,
  color,
  type,
  onClick,
  className,
  isDisabled = false,
  testId = 'icon-button',
  clickDelaySecs,
  isReversed = false
}) => {
  const [isClickDisabled, setClickDisabled] = useState(false);
  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (onClick) {
      if (clickDelaySecs) {
        setClickDisabled(true);
        setTimeout(() => setClickDisabled(false), clickDelaySecs * 1000);
      }
      onClick();
    }
  };

  return (
    <button
      data-testid={testId}
      className={`button-s ${color || ''} ${label ? 'label' : ''} ${className || ''} ${isDisabled || isClickDisabled ? 'disabled' : ''}`}
      type={type || 'button'}
      onClick={buttonHandler}
      disabled={isDisabled}
    >
      {isReversed ? (
        <>
          {label && <span className="button-label">{label}</span>}
          <Icon name={icon} size={size} />
        </>
      ) : (
        <>
          <Icon name={icon} size={size} />
          {label && <span className="button-label">{label}</span>}
        </>
      )}
    </button>
  );
};

export default IconButton;
