/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import axios from 'axios';
import { getCookie } from 'components/platform/cookiebar/helper';
import { getToken } from 'containers/authController';
import { HeadersProps, RequestProps } from 'types/apiResponse';

export const baseApi = axios.create({ timeout: 60000, headers: { 'Cache-Control': 'no-cache' } });

export const handleSuccessResponse = (response: any) => {
  const { data, status } = response;
  const { response: nestedResponse } = data;

  if (typeof data !== 'string') {
    data.statusCode = status;
  }

  if (nestedResponse) {
    nestedResponse.statusCode = status;
  }

  return nestedResponse || data;
};

export const handleErrorResponse = (error: any) => {
  const { response } = error;
  const { data, status } = response;

  if (error.code === 'ERR_NETWORK') {
    const statusCode = 504;
    return { statusCode, message: error.message };
  }

  if (typeof data !== 'string') {
    data.statusCode = status;
  }

  return data;
};

export const handleErrorInterceptor = function (error: any) {
  if (error?.response?.status === 401) {
    localStorage.clear();
    return Promise.reject(error);
  }

  return Promise.reject(error);
};

export const handleSuccessInterceptor = function (response: any) {
  return response;
};

export const request = async (props: RequestProps) => {
  const { method, endpoint, data, params, options = {} } = props;
  const language = getCookie('preferedLanguage') === 'de' ? 'de-DE' : getCookie('preferedLanguage');

  const headers: HeadersProps = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, private, must-revalidate, max-age=0, s-max-age=0',
    'Accept-Language': `${language || 'en-GB'}`,
    Pragma: 'no-cache'
  };
  const url = `${process.env.REACT_APP_API_URL}${endpoint}`;
  const token = await getToken();

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  axios.interceptors.response.use(handleSuccessInterceptor, handleErrorInterceptor);

  return axios({ url, method, data, headers, params, ...options })
    ?.then(handleSuccessResponse)
    .catch(handleErrorResponse);
};

// Load JSON file
export const loadText = async (key: string, lang = 'enGB') => {
  return baseApi.get(`${window.location.origin + '/json/'}${lang}/${key}.json`);
};
