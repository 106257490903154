/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { isEmpty } from 'lodash-es';
import { Provider, TariffValue } from 'types';

import { ProviderAccumulator, TariffAccumulator } from './processors.types';

const addTariffValue = (tariffs: TariffValue[], label: string, value: string, country: string) => {
  tariffs.push({ label, value, country });
};

const mapTariffsByProvider = (providers: Provider[]) => {
  if (isEmpty(providers)) return;

  const tariffs: TariffAccumulator = {};
  return providers.reduce((acc: TariffAccumulator, currentValue: Provider) => {
    const energyProvider = currentValue.energyProvider;
    const label = currentValue.tariffName;
    const value = currentValue.guid;
    const country = currentValue.country;

    if (Array.isArray(acc[energyProvider])) {
      addTariffValue(acc[energyProvider], label, value, country);
    } else {
      acc[energyProvider] = [{ label, value, country }];
    }

    return acc;
  }, tariffs);
};

const mapProvidersByCountry = (providers: Provider[]) => {
  if (!providers) {
    return [];
  }
  const initialValue: ProviderAccumulator = {};
  return providers.reduce((acc, item) => {
    const arr = acc[item.country] || [];

    if (!arr.find((provider: { value: string }) => provider.value === item.energyProvider)) {
      arr.push({
        label: item.energyProvider,
        value: item.energyProvider
      });
    }

    return {
      ...acc,
      [item.country]: arr
    };
  }, initialValue);
};

export const processProviders = (providers: Provider[]) => {
  return {
    tariffs: mapTariffsByProvider(providers),
    providers: mapProvidersByCountry(providers),
    providerList: providers
  };
};
