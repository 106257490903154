/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { loadText } from 'api/requests';
import Icon from 'components/elements/icon';
import { setIsAnalyticalCookiesAllowed } from 'containers/appStore';
import { useAppDispatch, useAppSelector } from 'customHooks';
import { isEmpty } from 'lodash-es';
import { memo, useState, useEffect } from 'react';

import { CC_GA, CC_NSC } from './consts';
import CookieBarForm from './cookiebarForm';
import { getCookie, IMPCookieBarData } from './helper';

const MPCookiebar = () => {
  const [data, setData] = useState<IMPCookieBarData>({});
  const [active, setActive] = useState(false);
  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state);

  const { userDetails, site } = store;
  const { language } = userDetails.personalInfo.preferences;
  const { isAnalyticalCookiesAllowed } = site;

  useEffect(() => {
    if (active) {
      loadText('cookiebar', language).then((res) => setData(res?.data));
    }
  }, [language, active]);

  useEffect(() => {
    const getNecessaryCookiesRes = getCookie(CC_NSC);
    const getAnalyticalCookieRes = getCookie(CC_GA) ? true : false;

    if (!active && isEmpty(getNecessaryCookiesRes)) {
      setActive(true);
    }

    if (!isAnalyticalCookiesAllowed) {
      dispatch(setIsAnalyticalCookiesAllowed(getAnalyticalCookieRes));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="cookiebar" data-testid="cookiebar">
      <div
        className="cookiebar__button"
        onClick={() => setActive(true)}
        data-testid="cookiebar-button"
      >
        <Icon name="logoblack" />
      </div>
      {active && (
        <>
          <div
            className="cookiebar__overlay"
            onClick={() => setActive(false)}
            data-testid="cookiebar-overlay"
          />
          <CookieBarForm data={data} setActive={setActive} language={language} />
        </>
      )}
    </div>
  );
};

const Cookiebar = memo(MPCookiebar);
export default Cookiebar;
